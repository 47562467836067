import React from 'react';
import Cookies from 'universal-cookie';
import { Redirect } from 'react-router-dom';
import Map from './Map';

class StoreFinder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verified: true
    };
  }

  componentDidMount() {
    this.renderRedirect();
  }

  renderRedirect = () => {
    const cookies = new Cookies();
    if (cookies.get('verified') !== 'true') {
      this.setState(
        {verified: false}
      )
    }
  }

  render() {
    if (this.state.verified) {
      return(
        <div style={{height:'100vh',backgroundColor: "#2d2f2d"}}>
            <Map/>
        </div>
      );
    } else {
      return (<Redirect to='/verify-age' />);
    }
  }
}

export default StoreFinder;
