import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { logout, authenticate } from '../actions'
import { store } from '../index'
import axios from 'axios'
import Cookies from 'universal-cookie'
import {BACKEND_IP} from '../constants/backendIp'
import GreenSpin from "../components/Loading/GreenSpin"
import SheepCart from "../components/animations/SheepCart"
import GearLoading from "../components/Loading/GearLoading"
import Button from "../components/CustomButtons/Button"
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/DeleteTwoTone'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  title: {
    [theme.breakpoints.down('xs')]: {
      fontSize:8,
      fontFamily: 'Raleway'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize:12,
      fontFamily: 'Raleway'
    },
    [theme.breakpoints.up('md')]: {
      fontSize:18,
      fontWeight:'bold',
      fontFamily: 'Raleway'
    },
  },
  table: {
    minWidth: '50vw',
  },
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    maxWidth:'200px',
  },
  body: {
    fontSize: 14,
    maxWidth:'25vw',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

function Favorites(props) {
  const classes = useStyles();
  const [favorites, setFavorites] = React.useState(null);
  const [processDelete, setProcessDelete] = React.useState([]);
  const [deleteOnRender, setDeleteOnRender] = React.useState([]);

  useEffect(() => {
    getFavorites()
  })

  const getFavorites = () => {
    const cookies = new Cookies();
    let tokenExp = cookies.get('userToken');
    let tokenId = cookies.get('userId');
    if (tokenExp) {
      axios.post('http://'+BACKEND_IP+'/api/cart/getFavorites', {
        tokenExp: tokenExp,
        tokenId: tokenId
      })
      .then((response) => {
        if (deleteOnRender.length !== 0) {
          deleteOnRender.map((procIndex,key) => {
            let procDelete = processDelete;
            const arrayIndex = processDelete.indexOf(procIndex);
            procDelete.splice(arrayIndex, 1);
            let dor = deleteOnRender;
            dor.splice(key, 1);
            setProcessDelete(procDelete)
            setDeleteOnRender(dor)
          });
        }
        if (response.data.success) {
          if (favorites === null ||
            favorites.length !== response.data.favorites.length) {
            setFavorites(response.data.favorites)
          }
        } else {
          store.dispatch(props.logout())
        }
      })
      .catch((error) => {
        console.log(error);
      });
    } else {
      // Give message to reauthenticate or redirect
    }
  }

  const removeItem = (item,index) => {
    return function () {
      const cookies = new Cookies();
      let tokenExp = cookies.get('userToken');
      let tokenId = cookies.get('userId');
      if (tokenExp) {
        let procDelete = processDelete;
        procDelete.push(index);
        setProcessDelete(procDelete)
        axios.post('http://'+BACKEND_IP+'/api/cart/removeFromFav', {
          tokenExp: tokenExp,
          tokenId: tokenId,
          item: item
        })
        .then((response) => {
          if (response.data.success) {
            let dor = deleteOnRender
            dor.push(index)
            setDeleteOnRender(dor)
            let authObj = props.auth
            let newFavList = []
            authObj.favorites.map((fav,key) => {
              if (fav.itemId !== item.itemId) {
                newFavList.push(fav)
              }
            })
            authObj.favorites = newFavList
            store.dispatch(props.authenticate(authObj))
            getFavorites()
          } else {
            store.dispatch(props.logout())
          }
        })
        .catch((error) => {
          console.log(error);
        });
      } else {
        // Give message to reauthenticate or redirect
      }
    }
  }

  const deleteButtonLoader = (row,index) => {
    if (processDelete.includes(index)) {
      return(
        <div style={{display:'flex',justifyContent:'center',
          alignItems:'center'}}>
          <GearLoading style={{paddingRight:'25px'}}/>
        </div>
      );
    } else {
      return(
        <IconButton onClick={removeItem(row,index)}
          style={{display:'flex',justifyContent:'center',
          alignItems:'center'}}>
          <DeleteIcon fontSize='large'/>
        </IconButton>
      );
    }
  }

  const displayFavorites = () => {
    if (favorites === null){
      return (
        <div style={{display:'flex',flexFlow:'column',alignItems:'center'}}>
          <SheepCart />
          <div style={{margin:20}}>Fetching your favorites...</div>
        </div>
      )
    } else if (favorites.length === 0) {
      return (
        <div style={{
          fontFamily: 'Raleway',
          color: 'white', fontSize: '22px' }}>
          <div style={{display: 'flex', flexDirection: 'column',
            alignItems: 'center',justifyContent: 'center'}}>
            <div>Your favorites list is empty.</div>
              <Link to={'/'} href="#top" style={{margin:20}}>
                <Button color='primary'>Browse Products</Button>
              </Link>
            <div>Go ahead, get yourself something nice</div>
          </div>
        </div>
      );
    } else {
        return(
          <div>
            <Paper className={classes.root} style={{minWidth:'80vw'}}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Strain</StyledTableCell>
                    <StyledTableCell>Price</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {favorites.map((row, index) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        <Link to={'/item/'+row.pic} href="#top" style={{ textDecoration: 'none',color:'black' }}>
                          <div style={{border: '2px solid black',borderRadius: '5px',
                            display:'flex',flexDirection:'column',alignItems:'center',
                            maxWidth:'200px',backgroundColor:'green',zIndex:10}}>
                            <img width='100%' src={"http://"+BACKEND_IP+"/"+row.pic} alt={'/images/defaultLogo.png'}/>
                            <Typography className={classes.title}>
                              {row.title}
                            </Typography>
                          </div>
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell>{row.price}</StyledTableCell>
                      <StyledTableCell align="right"
                        style={{width:'50px'}}>
                        {deleteButtonLoader(row,index)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </div>
        );
    }
  };

  if (props.auth !== -1) {
    return(
      <div>
        <div style={{backgroundColor:"#2d2f2d",paddingTop:80}}>
            <div style={{
              display: 'flex', flexDirection: 'column',
              alignItems: 'center',justifyContent: 'center',
              color: 'white', fontSize: '22px' }}>
              <div style={{fontSize: '46px' }}>Favorites</div>
              <div style={{ paddingTop:50, paddingBottom:200 }}>
              <div>{displayFavorites()}</div>
            </div>
          </div>
        </div>
        <img src={'/images/foliage.jpg'}
        alt={'/images/foliage.jpg'}
        style={{width:'100%'}}>
        </img>
      </div>
    )
  } else {
    return (
      <div>
        <div style={{ backgroundColor: "#2d2f2d"}}>
          <div style={{
            paddingTop:200, paddingBottom:200,
            display: 'flex', flexDirection: 'column',
            alignItems: 'center',justifyContent: 'center',
            color: 'white', fontSize: '22px',paddingTop:'40px'}}>
            <div style={{fontSize: '36px'}}>Favorites</div>
            <div style={{marginTop: '100px'}}>Please log
              in so you can add items to your favorites list.
            </div>
          </div>
        </div>
        <img src={'/images/herb.jpg'}
        alt={'/images/herb.jpg'}
        style={{width:'100%'}}>
        </img>
      </div>
    );
  }
  }

  const mapStateToProps = state => (
  {
    auth: state.auth,
  }
  );

  export default withRouter(connect(mapStateToProps, { logout,authenticate })(Favorites));
