import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker, Polygon } from 'google-maps-react';
import { Route } from 'react-router-dom';
import { mapStyles } from './mapStyles';
// import dispensaryLogoList from './dispensaryLogoList.json';

const style = {
  zIndex: 4
};

// const dispensaryLogoList = require('json!./dispensaryLogoList.json');

class MapContainer extends Component {

  constructor(props) {
    super(props);

    this.state = { redirect: "",
    stores: [

          {lat: 42.64826481489633, lng: -71.79079252791931,
            title:"United Cult",
            path: "https://www.unitedcult.com/order-now?dtche%5Bbrands%5D=Bailey%27s+Buds&dtche%5Bcategory%5D=flower",
            logo: "unitedCultLogo.png"},
          {lat: 42.364824205078406, lng: -71.10241596113414,
            title:"Yamba",
            path: "https://yambamarket.com/menu/flower/",
            logo: "yambaMarketLogo.png"},
          {lat: 42.77277724106098, lng: -71.08576045111735,
            title:"Stem",
            path: "https://stemhaverhill.com/onlinemenu",
            logo: "stemLogo.png"},
          {lat: 42.3699012040713, lng: -71.03793457311153,
            title:"Boutiq",
            path: "https://bostonboutiq.com/?dtche%5Bpath%5D=brands%2Fbaileys-buds",
            logo: "boutiqLogo.png"},
          {lat: 42.7366029892842, lng: -70.814630734854,
            title:"Fine Fettle",
            path: "https://www.finefettle.com/massachusetts/rowley-dispensary/recreational/?filters%5Bbrand%5D%5B%5D=Bailey%27s%20Buds",
            logo: "fineFettleLogo.png"},
          {lat: 42.461848750468896, lng: -73.22752287644447,
            title:"Temescal",
            path: "https://ma.temescalwellness.com/pittsfield-recreational?dtche%5Bpath%5D=brands%2Fbaileys-buds",
            logo: "temescalLogo.png"},
          {lat: 42.34370367387132, lng: -71.2411415310451,
            title:"Temescal",
            path: "https://ma.temescalwellness.com/framingham-recreational?dtche%5Bpath%5D=brands%2Fbaileys-buds",
            logo: "temescalLogo.png"},
          {lat: 42.19965234077572, lng: -73.36019858281355,
            title:"Farnsworth",
            path: "https://www.farnsworthfinecannabis.com/menu?dtche%5Bpath%5D=brands%2Fbaileys-buds",
            logo: "farnsworthGifLogo.gif"},
          {lat: 42.19840940503358, lng: -72.60949131656945,
            title:"Boston Bud Factory",
            path: "https://dutchie.com/dispensary/boston-bud-factory/brands/baileys-buds",
            logo: "BBFLogo.webp"},
          {lat: 42.69387198954802, lng: -71.25755550833857,
            title:"Tree House",
            path: "https://visittreehousema.com/categories/flower/",
            logo: "TreeHouseLogo.png"},
          {lat: 42.66585096743453, lng: -71.57757563177813,
            title:"Tree House",
            path: "https://visittreehousema.com/categories/flower/",
            logo: "TreeHouseLogo.png"},
          {lat: 42.48163259075955, lng: -70.90318593162796,
            title:"Terpene Journey",
            path: "https://www.terpenejourney.com/menu/?brand=Bailey%27s+Buds",
            logo: "TerpeneJourneyLogo.png"},
          {lat: 42.51886321091435, lng: -70.89372576971931,
            title:"Seagrass Salem",
            path: "https://seagrasssalem.com/order-online?",
            logo: "SeagrassLogo.png"},
          {lat: 42.4566893852317, lng: -70.97283190040882,
            title:"Olde World Remedies",
            path: "https://oldeworldremedies.com/menu/marijuana-flower-for-sale-lynn-ma/",
            logo: "OldeWorldLogo.png"},
          {lat: 42.36739383718997, lng: -71.78243461177239,
            title:"Campfire Cannabis",
            path: "https://campfirecannabis.com/dispensary/west-boylston/menu/?dtche%5Bbrands%5D=Bailey%27s+Buds&dtche%5Bcategory%5D=flower",
            logo: "CampfireCannabis.png"},
          {lat: 42.595926245573544, lng: -72.22185510000001,
            title:"Boston Garden",
            path: "https://boston.garden/order/?dtche%5Bpath%5D=brands%2Fbaileys-buds",
            logo: "bostonGardenLogo.png"},
          {lat: 42.37279144670064, lng: -71.0786380237714,
            title:"Boston Garden",
            path: "https://boston.garden/order/?dtche%5Bpath%5D=brands%2Fbaileys-buds",
            logo: "bostonGardenLogo.png"},
          {lat: 42.10689395516884, lng: -70.75750029001553,
            title:"South Shore Buds",
            path: "https://southshorebuds.com/products/baileys-buds-noxious-brew-i-h-for-sale-marshfield-ma/",
            logo: "southShoreBudsLogo.png"},
          {lat: 42.70310490931626, lng: -73.18430746931483,
            title:"Silver Therapeutics",
            path: "https://silver-therapeutics.com/williamstown-ma-dispensary-menu/?dtche%5Bpath%5D=brands%2Fbaileys-buds",
            logo: "SilverTherapeuticsLogo.png"},
          {lat: 41.97300259615581, lng: -70.70615758414044,
            title:"Elevated Roots",
            path: "https://elevatedrootsma.com/categories/flower/",
            logo: "elevatedRootsLogo.png"},
          {lat: 41.99739652257238, lng: -70.84263497835238,
            title:"Elevated Roots",
            path: "https://elevatedrootsma.com/categories/flower/",
            logo: "elevatedRootsLogo.png"},
          {lat: 42.32765535345601, lng: -73.08362946027114,
            title:"Canna Corner",
            path: "https://cannacornerbecket.com/menu/?dtche%5Bbrands%5D=Bailey%27s+Buds&dtche%5Bcategory%5D=flower",
            logo: "cannaCornerLogo.png"},
          {lat: 42.243185935408114, lng: -71.82630636318099,
            title:"Cookies",
            path: "https://www.cookiesworcester.com/?dtche%5Bpath%5D=brands%2Fbaileys-buds",
            logo: "cookiesLogo.png"},
          {lat: 42.34925482959258, lng: -71.08634766859593,
            title:"Rooted In",
            path: "https://www.rootedinroxbury.com/shop/",
            logo: "RootedLogo.png"},
          {lat: 42.33610155139927, lng: -71.60365840930422,
            title:"Kosa",
            path: "https://www.kosa.co/order/?dtche%5Bpath%5D=brands%2Fbaileys-buds",
            logo: "KosaLogo.PNG"},
          {lat: 42.40785504565608, lng: -71.13475938887822,
            title:"Eskar",
            path: "https://eskarma.com/arlington-menu/",
            logo: "EskarLogo.png"},
          {lat: 42.127622623330375, lng: -71.61995727453026,
            title:"Eskar",
            path: "https://eskarma.com/northbridge-menu/",
            logo: "EskarLogo.png"},
          {lat: 41.666361979718054, lng: -71.1554961863288,
            title:"Northeast Alternatives",
            path: "https://www.nealternatives.com/menu/?dtche%5Bbrands%5D=baileys-buds&dtche%5Bsortby%5D=popular&dtche%5Bcategory%5D=flower",
            logo: "NortheastAlternativesLogo.png"},
          {lat: 42.052554980745136, lng: -70.18427734406069,
            title:"Haven",
            path: "https://www.havencenter.me/provincetown-dispensary-menu?dtche%5Bcategory%5D=flower",
            logo: "HavenLogo.png"},
          {lat: 41.77655482221507, lng: -70.0112110079238,
            title:"Haven",
            path: "https://www.havencenter.me/provincetown-dispensary-menu?dtche%5Bcategory%5D=flower",
            logo: "HavenLogo.png"},
          {lat: 41.78033307730062,  lng: -70.73718316550355,
            title:"Trade Roots",
            path: "https://traderoots.buzz/menu/?product=651cb34bdb34170001e66408",
            logo: "TradeRootsLogo.png"},
          {lat: 42.10096724768637, lng: -71.06610404657422,
            title:"Green4All",
            path: "https://shop.green4allbrockton.com/products",
            logo: "Green4AllLogo.png"},
          {lat: 42.51852357408795, lng: -73.22829430259095,
            title:"Liberty Market",
            path: "https://dutchie.com/stores/liberty-market/products/flower",
            logo: "libertyMarketLogo.png"},
          {lat: 42.39758804794284, lng: -71.5920186135424,
            title:"Native Sun",
            path: "https://www.nativesuncannabis.com/menu/",
            logo: "NativeSunLogo.png"},
          {lat: 41.93858830011763, lng: -71.34906571308942,
            title:"Native Sun",
            path: "https://www.nativesuncannabis.com/menu/",
            logo: "NativeSunLogo.png"},
          {lat: 42.338536267620356, lng: -71.03781066740581,
            title:"Native Sun",
            path: "https://www.nativesuncannabis.com/menu/",
            logo: "NativeSunLogo.png"},
          {lat: 42.3820457230596, lng: -71.07890026683553,
            title:"The Heritage Club",
            path: "https://heritageclubthc.com/shop-boston-cannabis/",
            logo: "THCLogo.png"},
          {lat: 42.530578663046086, lng: -71.66295363269836,
            title:"Boom X",
            path: "https://www.boomxcannabis.com/menu/?dtche%5Bpath%5D=brands%2Fbaileys-buds",
            logo: "boomxLogo.png"},
          {lat: 42.24511322887221, lng: -70.97165689245998,
            title:"Quincy Cannabis Co.",
            path: "https://quincycannabis.co/menu/",
            logo: "QuincyLogo.png"}
          ]
    };
  }

  componentDidMount () {
    this.setState({
      redirect: ""
    })
  }

  _mapLoaded(mapProps, map) {
     map.setOptions({
        styles: mapStyles,
        gestureHandling: "cooperative"
     })
  }

  redirectToStore = (path) => {
    let fullPath = path
    this.setState({
      redirect: fullPath
    })
  }

  displayMarkers = () => {
    return this.state.stores.map((store, index) => {
      return (
        <Marker key={index} id={index} position={{
           lat: store.lat,
           lng: store.lng
         }}
         title={store.title}
         onClick={() => this.redirectToStore(store.path)}
         icon={{
            url: "https://green-thumb-growers.uk.r.appspot.com/dispensaryLogos/" + store.logo,
            anchor: new this.props.google.maps.Point(16,16),
            scaledSize: new this.props.google.maps.Size(32,32)
          }}
        />
      )
    })
  }

  render() {
    var everythingElse = [
      {lat: 0, lng: -90},
      {lat: 0, lng: 90},
      {lat: 90, lng: -90},
      {lat: 90, lng: 90}
    ];

    var triangleCoords = [
      {lng: -71.4982590001295, lat: 42.0172719998823},  {lng: -71.5277020004221, lat: 42.0150949999646},{lng: -71.7991879997258, lat: 42.0080529997619},{lng: -71.8006709996823, lat: 42.0235140003054},{lng: -72.0718260003466, lat: 42.0276369996794},
      {lng: -72.1431509996205, lat: 42.0305220000485},	{lng: -72.5315489997541, lat:	42.0345780002288},	{lng: -72.5727779998806, lat:	42.0302190001131},	{lng: -72.5821629996911, lat:	42.0247409997585},	{lng: -72.6071689998099, lat:	42.0251480001561},	{lng: -72.6079780000969, lat:	42.0310830001198},	{lng: -72.6401909995856, lat:	42.0320480002329},	{lng: -72.6993299998908, lat:	42.0369569998856},	{lng: -72.7558940000953, lat:	42.0363490002855},
      {lng: -72.7573009998838, lat:	42.0333809997234},	{lng: -72.7525530002637, lat:	42.0312490002311},	{lng: -72.752061999994, lat:	42.0290250003208},	{lng: -72.7573410000668, lat:	42.0209789998813},	{lng: -72.7605670002707, lat:	42.0226500001952},	{lng: -72.7634870001529, lat:	42.0211839999834},	{lng: -72.7652520001417, lat:	42.0223660000544},	{lng: -72.7644770001898, lat:	42.0208729999502},	{lng: -72.7658060001605, lat:	42.0195680000881},
      {lng: -72.7629470003777, lat: 42.0196829997021},	{lng: -72.7589500004199, lat:	42.0154690003262},	{lng: -72.7629769996166, lat:	42.0134659998403},	{lng: -72.7615010002312, lat:	42.009302000017},	{lng: -72.7662530000493, lat:	42.007870999957},	{lng: -72.7667330004483, lat:	42.0032660002259},	{lng: -72.8170529997591	, lat: 41.9976850001029},	{lng: -72.8137839998975, lat:	42.0367440002959},	{lng: -73.0368019999179, lat:	42.0392939998041},
      {lng: -73.4285149999438, lat:	42.050476000308},	  {lng: -73.4970180001432, lat:	42.0496240001605},	{lng: -73.5082099997752, lat:	42.0861339997249},	{lng: -73.2648270000244, lat:	42.7458509997267},	{lng: -72.8091130002702, lat:	42.7365809997488},	{lng: -71.6965050003828, lat:	42.7057220002706},	{lng: -71.294636999719, lat:	42.6970419997386},	{lng: -71.2789490003619, lat:	42.7113500001148},	{lng: -71.2678730001824, lat:	42.7260260000064},
      {lng: -71.2454260002708, lat:	42.7425459997377},	{lng: -71.2238299995806, lat:	42.746483999792},	{lng: -71.1817860002177, lat:	42.7373410001663},	{lng: -71.1861399996525, lat:	42.790935000103},	{lng: -71.1666469999582, lat:	42.8088810002302},	{lng: -71.1328050000249, lat:	42.8214759998798},	{lng: -71.0644159996284, lat:	42.8062749997989},	{lng: -71.054003999971, lat:	42.8332849997371},  {lng: -71.0449199995611, lat:	42.8486669999432},
      {lng: -71.0312010004073, lat:	42.8590869998415},	{lng: -70.9670180002059, lat:	42.8688000001669},	{lng: -70.9295940004165, lat:	42.8850319999391},	{lng: -70.9146309998842, lat:	42.8866479999702},	{lng: -70.9035329999634, lat:	42.8867780003161},	{lng: -70.8856019998684, lat:	42.8828339998974},	{lng: -70.8477179997714, lat:	42.8608669997241},	{lng: -70.8202379998343, lat:	42.8717869998011},	{lng: -70.7350050003624, lat:	42.8746850002148},
      {lng: -70.7488299998215, lat:	42.8588309997528},	{lng: -70.7455219996017, lat:	42.844442999991},	{lng: -70.7390380003052, lat:	42.8358180001912},	{lng: -70.7348739999428, lat:	42.8244769999026},	{lng: -70.733654999577, lat:	42.8036590000583},	{lng: -70.7372249997375, lat:	42.7879559998839},	{lng: -70.7318390004329, lat:	42.778356999796},	{lng: -70.7258480001575, lat:	42.7590199998342},	{lng: -70.7192700002513, lat:	42.7500949997928},
      {lng: -70.7143240002644, lat:	42.7319110001266},	{lng: -70.7025800002633, lat:	42.7241619998579},	{lng: -70.6829300002102, lat:	42.7288300001715},	{lng: -70.6613140003269, lat:	42.7366099997525},	{lng: -70.6416640002738, lat:	42.7397230003175},	{lng: -70.6259430004064, lat:	42.7397239998576},	{lng: -70.5493059996511, lat:	42.7241640000998},	{lng: -70.5296549997731, lat:	42.7070479999741},	{lng: -70.5139339999057, lat:	42.6805940002467},
      {lng: -70.5021430000489, lat:	42.6354710001442},	{lng: -70.5021430000489, lat:	42.6245789997201},	{lng: -70.5119680000755, lat:	42.6059070003281},	{lng: -70.521793000102, lat:	42.5934590003015},	{lng: -70.5453739999907, lat:	42.5701190000589},	{lng: -70.5827090000916, lat:	42.5530039997101},	{lng: -70.6062899999802, lat:	42.5312200002648},	{lng: -70.6903929996731, lat:	42.2789989998625},	{lng: -70.6887039998523, lat:	42.2634680000636},
      {lng: -70.6840609998142, lat:	42.2538360001762},	{lng: -70.6486220001216, lat:	42.2373600000176},	{lng: -70.6401129996469, lat:	42.2283719999445},	{lng: -70.6363299999494, lat:	42.2163859999978},	{lng: -70.6363299999494, lat:	42.2029030000567},	{lng: -70.6429460003888, lat:	42.1781849997824},	{lng: -70.6083810003822, lat:	42.1384790000596},	{lng: -70.27743000036, lat:	42.1143450000495},	{lng: -70.2494719996737, lat:	42.1269259998918},
      {lng: -70.2291749997153, lat:	42.1311889998466},	{lng: -70.2062209996404, lat:	42.1330540001349},	{lng: -70.1953999997291, lat:	42.1315490001428},	{lng: -70.1725419999137, lat:	42.1345150001353},	{lng: -70.1206910000675, lat:	42.1251010002563},	{lng: -70.0670979997988, lat:	42.1053269997947},	{lng: -70.0403019995769, lat:	42.0916340002055},	{lng: -70.0115909995784, lat:	42.0733779998879},	{lng: -69.9962789999649, lat:	42.0596870000531},
      {lng: -69.9694819999181, lat:	42.0292540002582},	{lng: -69.9482099999913, lat:	42.0001059996733},	{lng: -69.9291089999342	, lat: 41.9784759998115},	{lng: -69.9091779996737	, lat: 41.9420620001113},	{lng: -69.8953049996357	, lat: 41.9067530000365},	{lng: -69.8800180001366	, lat: 41.8619780002724},	{lng: -69.8723790000483	, lat: 41.8315860002394},	{lng: -69.8665250000403	, lat: 41.8170109997305},	{lng: -69.8655950002778	, lat: 41.790979999688},
      {lng: -69.8600579995638	, lat: 41.7585029999617},	{lng: -69.8589500004244	, lat: 41.706915999792},	{lng: -69.862876000237	, lat: 41.6731530002414},	{lng: -69.8681250001726	, lat: 41.6567909997122},	{lng: -69.8744589996814	, lat: 41.6450260000928},	{lng: -69.8890120001349	, lat: 41.6251959998133},	{lng: -69.8916619996803	, lat: 41.6171860001007},	{lng: -69.8985619998018	, lat: 41.6060960001879},	{lng: -69.9153990001029	, lat: 41.5892430001949},
      {lng: -69.9215169997016	, lat: 41.5709000003103}, {lng: -69.9213450001724	, lat: 41.5622819997448},	{lng: -69.9246319995774	, lat: 41.5446459998445},	{lng: -69.9289940003065	, lat: 41.5321519999962},	{lng: -69.9364009996929	, lat: 41.5219360003196},	{lng: -69.946613000232	, lat: 41.5121520002017},	{lng: -69.9674299998741	, lat: 41.5001159998395},	{lng: -69.9883059999657	, lat: 41.4944959996932},	{lng: -69.9981689996271	, lat: 41.4933989996874},
      {lng: -69.9994580003132	, lat: 41.4905269999489}, {lng: -70.0138619999054	, lat: 41.4774389999746},	{lng: -70.0236769998862	, lat: 41.4719479996861},	{lng: -70.0403130003459	, lat: 41.4659599997881},	{lng: -70.0554369996384	, lat: 41.4641099998664},	{lng: -70.0737419998273	, lat: 41.4650200003073},	{lng: -70.0849040002204	, lat: 41.4674930002806},	{lng: -70.1015079999947	, lat: 41.4744439998587},	{lng: -70.1143940003687	, lat: 41.484082999772},
      {lng: -70.1225949999735	, lat: 41.494498999956},  {lng: -70.1266770003201	, lat: 41.5036980000416},	{lng: -70.1285539995635	, lat: 41.5247320001484},	{lng: -70.1225589999885	, lat: 41.5378330000932},	{lng: -70.1167190002241	, lat: 41.5449930000805},	{lng: -70.1020290004016	, lat: 41.5563520000718},	{lng: -70.0803160004339	, lat: 41.5644540001525},	{lng: -70.0735860001917	, lat: 41.5777080000079},	{lng: -70.0633519999112	, lat: 41.5901729998858},
      {lng: -70.068046000003	, lat: 41.6029539998221}, {lng: -70.0685690000597	, lat: 41.6106969999004},	{lng: -70.0779339997787	, lat: 41.6125280000851},	{lng: -70.1269519997814	, lat: 41.6002029999392},	{lng: -70.1384909997431	, lat: 41.5986920000406},	{lng: -70.1603569998717	, lat: 41.5993699999063},	{lng: -70.181499999927	, lat: 41.5935469996933},	{lng: -70.2080400000558	, lat: 41.5841500000635},	{lng: -70.2255579996991	, lat: 41.5685609998918},
      {lng: -70.2494630003511	, lat: 41.5601459998555}, {lng: -70.267428999708	, lat: 41.5583729998819},	{lng: -70.2788980002478	, lat: 41.5593159998274},	{lng: -70.2899650002064	, lat: 41.5616390001618},	{lng: -70.3112539998516	, lat: 41.5710619998069},	{lng: -70.3315490001602	, lat: 41.5678980001613},	{lng: -70.346336999892	, lat: 41.5684529996758},	{lng: -70.3642060000628	, lat: 41.5621959999925},	{lng: -70.395164000099	, lat: 41.5567220002936},
      {lng: -70.4238960000139	, lat: 41.5287319999004}, {lng: -70.4485670003969	, lat: 41.5130929997579},	{lng: -70.4664359996694	, lat: 41.5061599996814},	{lng: -70.4897400004469	, lat: 41.5018560001525},	{lng: -70.4982089998403	, lat: 41.5025649998046},	{lng: -70.5086029999544	, lat: 41.4989649998411},	{lng: -70.4994719996888	, lat: 41.4882329997196},	{lng: -70.4949419996285	, lat: 41.4796320002276},	{lng: -70.4896890003933	, lat: 41.4629769999277},
      {lng: -70.4639500001555	, lat: 41.4680000002764}, {lng: -70.436449000302	, lat: 41.4689190002762},	{lng: -70.4210869995615	, lat: 41.4644159998375},	{lng: -70.4059280001089	, lat: 41.4570130000839},	{lng: -70.392891999947	, lat: 41.4454800002398},	{lng: -70.3858739998247	, lat: 41.4337210000735},	{lng: -70.3808070003623	, lat: 41.4119960002151},	{lng: -70.3806929996612	, lat: 41.3853609997335},	{lng: -70.3834490004102	, lat: 41.3699329999605},
      {lng: -70.3701119999494	, lat: 41.3687689997282}, {lng: -70.3530849996776	, lat: 41.3814549999708},	{lng: -70.3382890004481	, lat: 41.3885500002178},	{lng: -70.3235929998795	, lat: 41.3916070000998},	{lng: -70.3024380001286	, lat: 41.3896250000119},	{lng: -70.2832140000478	, lat: 41.3928850000952},	{lng: -70.2672830001182	, lat: 41.3934259999915},	{lng: -70.2476890001415	, lat: 41.389890999694},	{lng: -70.2381419999493	, lat: 41.3862049997726},
      {lng: -70.2216219998407	, lat: 41.3717690003342}, {lng: -70.2151150000795	, lat: 41.3602750000297},	{lng: -70.2082969999738	, lat: 41.3580249997331},	{lng: -70.1983830002588	, lat: 41.3590249997287},	{lng: -70.1749060001272	, lat: 41.3572900001144},	{lng: -70.1599919999987	, lat: 41.3522420002442},	{lng: -70.1486990000843	, lat: 41.3452159999198},	{lng: -70.1379400000973	, lat: 41.3463710001891},	{lng: -70.1193350003327	, lat: 41.3526380000249},
      {lng: -70.0978440001228	, lat: 41.3535839998647}, {lng: -70.1113279999984	, lat: 41.370389000087},	{lng: -70.1164399996666	, lat: 41.3861649997657},	{lng: -70.1165409999489	, lat: 41.3951320002785},	{lng: -70.1127339999619	, lat: 41.4081259999002},	{lng: -70.1058189997718	, lat: 41.4198659996915},	{lng: -70.0944120000546	, lat: 41.430810000259},	{lng: -70.0651739998014	, lat: 41.4426870000534},	{lng: -70.0442719997705	, lat: 41.4438010000985},
      {lng: -70.0259020001826	, lat: 41.4410790000613}, {lng: -70.0064670002146	, lat: 41.4338980001632},	{lng: -69.9817749999152	, lat: 41.4152079996695},	{lng: -69.969634000254	, lat: 41.4035110001303},	{lng: -69.902947999922	, lat: 41.3052069998523},	{lng: -69.8948850004095	, lat: 41.2903720002629},	{lng: -69.892445000028	, lat: 41.2790899999616},	{lng: -69.8922090000265	, lat: 41.2713229997718},	{lng: -69.8944719999576	, lat: 41.2592400003069},
      {lng: -69.8990100004138	, lat: 41.2455380002415}, {lng: -69.9103190002217	, lat: 41.226848999808},	{lng: -69.9229109999696	, lat: 41.2158399999086},	{lng: -69.9420079998287	, lat: 41.2045200001556},	{lng: -69.9731819997749	, lat: 41.1935449998242},	{lng: -70.0124829997061	, lat: 41.1870529999862},	{lng: -70.0726659995766	, lat: 41.1916339997843},	{lng: -70.1096840003834	, lat: 41.1898029999494},	{lng: -70.1788510002065	, lat: 41.204530999762},
      {lng: -70.2170170003357	, lat: 41.2181759996645}, {lng: -70.2399820002812	, lat: 41.2284630002031},	{lng: -70.2857360004451	, lat: 41.2423460000546},	{lng: -70.294484000396	, lat: 41.2464989998386},	{lng: -70.3142979995823	, lat: 41.2605319998795},	{lng: -70.3350900000084	, lat: 41.2727789997228},	{lng: -70.3440830001817	, lat: 41.2761460000465},	{lng: -70.3744660002826	, lat: 41.2694579998283},	{lng: -70.3967769997518	, lat: 41.2730140000332},
      {lng: -70.4087330003633	, lat: 41.2772939998218}, {lng: -70.4290749996292	, lat: 41.2686510000126},	{lng: -70.4447579998618	, lat: 41.2655130002192},	{lng: -70.4598510000905	, lat: 41.2652530001518},	{lng: -70.4756349997071	, lat: 41.2702199997325},	{lng: -70.4933080000593	, lat: 41.2799749997263},	{lng: -70.5121990000541	, lat: 41.2983130000821},	{lng: -70.5718479999973	, lat: 41.3000939997336},	{lng: -70.6066719995716	, lat: 41.2994100001341},
      {lng: -70.685019999889	, lat: 41.2931759997995}, {lng: -70.6928740000624	, lat: 41.289638999857},	{lng: -70.7031059997948	, lat: 41.2890400002654},	{lng: -70.7073620002186	, lat: 41.280218000118},	{lng: -70.716499000332	, lat: 41.2694520001075},	{lng: -70.7348429999807	, lat: 41.2592309999923},	{lng: -70.7336670001709	, lat: 41.2501159998612},	{lng: -70.734752999569	, lat: 41.2462600001305},	{lng: -70.7443070003322	, lat: 41.2260299998857},
      {lng: -70.7541880004353	, lat: 41.2160909998136}, {lng: -70.7678370001672	, lat: 41.2064899998415},	{lng: -70.7835820003241	, lat: 41.2012189999531},	{lng: -70.8059499996947	, lat: 41.198741999749},	{lng: -70.8236449997882	, lat: 41.2006489998182},	{lng: -70.8477460002589	, lat: 41.2058140000951},	{lng: -70.8663429996275	, lat: 41.2153970000656},	{lng: -70.8744739998104	, lat: 41.2213809999223},	{lng: -70.8849730004047	, lat: 41.2337840000353},
      {lng: -70.8905410001825	, lat: 41.2430910002012}, {lng: -70.8942030004045	, lat: 41.2594390002671},	{lng: -70.893594999959	, lat: 41.2679940002766},	{lng: -70.890336999968	, lat: 41.2773619999349},	{lng: -70.8856830000592	, lat: 41.285066000258},	{lng: -70.8781820000632	, lat: 41.2921469997483},	{lng: -70.8700269995908	, lat: 41.2970679999487},	{lng: -70.8844720000893	, lat: 41.3101620000409},	{lng: -70.8977219996131	, lat: 41.3274110001119},
      {lng: -70.9010049997184	, lat: 41.3346130001678}, {lng: -70.9038149999957	, lat: 41.3467269999578},	{lng: -70.9037389998278	, lat: 41.35508000006},	{lng: -70.901865999884	, lat: 41.3617700002809},	{lng: -70.8960169998988	, lat: 41.3701480001995},	{lng: -70.9183990004114	, lat: 41.3630330000731},	{lng: -70.9386639996845	, lat: 41.3539860002861},	{lng: -70.9557520000557	, lat: 41.3501609998456},	{lng: -70.9705210004192	, lat: 41.3491869999533},
      {lng: -70.9907480000575	, lat: 41.3512890001241}, {lng: -71.0147990002994	, lat: 41.3620199998079},	{lng: -71.0261030000844	, lat: 41.3716929996753},	{lng: -71.0327010000822	, lat: 41.3802730000702},	{lng: -71.0399180003961	, lat: 41.398258999969},	{lng: -71.0336040000805	, lat: 41.4140850001856},	{lng: -71.0534509997771	, lat: 41.4169589999607},	{lng: -71.0724039996962	, lat: 41.4255700000244},	{lng: -71.0850380001752	, lat: 41.4334670002087},
      {lng: -71.0885709996274	, lat: 41.4313149997622}, {lng: -71.1211160002754	, lat: 41.4927870000876},	{lng: -71.1198740003434	, lat: 41.4946040003201},	{lng: -71.1224369998502	, lat: 41.5220750003012},	{lng: -71.1316199999943	, lat: 41.5937159998945},	{lng: -71.1403079996708	, lat: 41.6048499999872},	{lng: -71.1419580000318	, lat: 41.612532999684},	{lng: -71.1401849996472	, lat: 41.6238689997119},	{lng: -71.1353070003307	, lat: 41.6284880000023},
      {lng: -71.1325570003278	, lat: 41.6603630002315}, {lng: -71.1761199996323	, lat: 41.6682479999186},	{lng: -71.1757840000716	, lat: 41.671493000104},	{lng: -71.1954790003306	, lat: 41.6750900002277},	{lng: -71.2610669997723	, lat: 41.7520820001837},	{lng: -71.3284719999796	, lat: 41.7806940001221},	{lng: -71.3303909999541	, lat: 41.7853810002203},	{lng: -71.3294569999937	, lat: 41.7883270000824},	{lng: -71.3327429995738	, lat: 41.7901599996962},
      {lng: -71.3346449998299	, lat: 41.7948350001564}, {lng: -71.3404810002946	, lat: 41.7970330000113},	{lng: -71.339332000249	, lat: 41.8085270002287},	{lng: -71.3475450004476	, lat: 41.8232460001313},	{lng: -71.3451979999526	, lat: 41.8276760001097},	{lng: -71.3354170003069	, lat: 41.8356450001951},	{lng: -71.3425500000569	, lat: 41.844655999714},	{lng: -71.3340399997574	, lat: 41.8626039999997},	{lng: -71.3408899996502	, lat: 41.8818040001622},
      {lng: -71.3387869995526	, lat: 41.8985930000188}, {lng: -71.3814629996506	, lat: 41.8935460000175},	{lng: -71.3814009997263, lat:	42.0187979997801},	{lng: -71.4982590001295, lat:	42.0172719998823}
    ];

    if (this.state.redirect === "") {
      return (
          <Map
            google={this.props.google}
            zoom={9}
            style={style}
            initialCenter={{ lat: 42.25, lng: -71.15}}
            mapTypeControl={false}
            onReady={(mapProps, map) => this._mapLoaded(mapProps, map)}
          >
          <Polygon
            paths= {[everythingElse, triangleCoords]}
            strokeColor= {'#FFFFFF'}
            strokeOpacity= {0.8}
            strokeWeight= {0}
            fillColor= {'#FFFFFF'}
            fillOpacity= {1} />
            {this.displayMarkers()}
          </Map>
      );
    } else {
      return(<Route component={() => {
     window.location.href = this.state.redirect;
     return null;
}}/>)
    }
  }
}


export default GoogleApiWrapper({
  apiKey: 'AIzaSyC6MJr-QDGQvqt4SrQDlaN2iKyUA-CvXnY'
})(MapContainer);

///Stores not currently with inventory
// {lng: 42.40187669597994, lat: -71.02312687831636,
//   title:"Western Front",
//   path: "https://www.westernfrontus.com/menu/?dtche%5Bproduct%5D=golden-papaya-3-5g-flower",
//   logo: "westernfront.png"},
// {lng: 42.56212650870642, lat: -71.48448253390822,
//   title:"Collective Premium Cannabis",
//   path: "https://collective-cannabis.com/littleton-dispensary-menu/?dtche%5Bbrands%5D=Bailey%27s+Buds&dtche%5Bcategory%5D=flower",
//   logo: "cannabiscollective.png"},
// {lng: 42.36739383718997, lat: -71.78243461177239,
//   title:"Campfire Cannabis",
//   path: "https://campfirecannabis.com/dispensary/west-boylston/menu/?dtche%5Bbrands%5D=Bailey%27s+Buds&dtche%5Bcategory%5D=flower",
//   logo: "CampfireCannabis.png"},
// {lng: 42.32414255266268, lat: -71.79878542924948,
//   title:"The Botanist Worcester",
//   path: "https://www.shopbotanist.com/locations/worcester/shop-adult-use/",
//   logo: "theBotanistLogo.png"},
// {lng: 41.70226749957439, lat: -71.16413816559547,
// title:"Northeast Alternatives",
// path: "https://www.westernfrontus.com/menu/?dtche%5Bproduct%5D=golden-papaya-3-5g-flower",
// logo: "northeastalt.png"},
// {lng: 42.24933614752032, lat: -71.7206983004162,
//   title:"The Botanist Shrewsbury",
//   path: "https://www.shopbotanist.com/locations/shrewsbury/shop-adult-use/",
//   logo: "theBotanistLogo.png"},
// {lat: 42.30739078811812, lng: -71.05507685663274,
//   title:"Budega",
//   path: "https://highprofilecannabis.com/shop/high-profile-budega-dorchester-dispensary?dtche%5Bbrands%5D=Bailey%27s+Buds&dtche%5Bcategory%5D=flower",
//   logo: "highprofileLogo.png"},
// {lat: 42.66396996611487, lng: -71.30378376824576,
// {lat: 42.310527178440616, lng: -71.08193548435142,
//   title:"Pure Oasis",
//   path: "https://www.mypureoasis.com/shop-cannabis/?dtche%5Bcategory%5D=flower",
//   logo: "pureOasisLogo.png"},
// //   title:"Reverie 73",
// //   path: "https://reverie73.com/lowell-menu/?dtche%5Bbrands%5D=baileys-buds&dtche%5Bcategory%5D=flower",
// //   logo: "reverie73Logo.png"},
// {lat: 42.55196274681727, lng: -71.5660365329441,
//   title:"Gage",
//   path: "https://gagecannabisco.com/ayer-massachusetts-dispensary-menu/?dtche%5Bbrands%5D=Bailey%27s+Buds&dtche%5Bcategory%5D=flower",
//   logo: "gageLogo.png"},
// {lat: 42.32227044787707, lng: -71.11025250012285,
//   title:"SEED",
//   path: "https://seedyourhead.com/cannabis-dispensary-boston-ma/cannabis-flower/flower-for-sale/golden-papaya-flower-3-54g-baileys-buds/",
//   logo: "SEEDLogoTransparent.png"},
