import React, { Component } from 'react'
import Main from './Main'
import DrawerMenu from './drawer/DrawerMenu'
import Header from './Header'
import Footer from './Footer'
import Map from './map/Map'
import { Link, NavLink } from 'react-router-dom'
import Cookies from 'universal-cookie'
import TitleParticles from './components/animations/TitleParticles'
import baileysbudhouse from './assets/img/baileysbudhouse.png'
import { Button, ButtonGroup, Fade } from "@material-ui/core"
import VegPlantsPhoto from './assets/img/vegplants.jpg'
import LadybugPhoto from './assets/img/ladybug.jpg'
import VegCanopyPhoto from './assets/img/vegcanopy.jpg'
import VegPlants2Photo from './assets/img/vegplants2.jpg'
import VegPrettyPhoto from './assets/img/vegpretty.jpg'
import Logosubscribe from './components/CustomDialog/LogoSubscribe'
import InstagramIcon from './assets/img/Instagram-Logo.png'
import Carousel from "react-slick"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from '@material-ui/core/typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AddIcon from '@material-ui/icons/Add'
import GridContainer from "./components/Grid/GridContainer"
import GridItem from "./components/Grid/GridItem"
import Card from "./components/Card/Card"
import goldenpapaya from "./assets/img/goldenpapaya.webp"
import GP1 from './assets/img/goldenpapaya/gp1.webp'
import GP2 from './assets/img/goldenpapaya/gp2.webp'
import GP3 from './assets/img/goldenpapaya/gp3.webp'
import GP4 from './assets/img/goldenpapaya/gp4.webp'
import GP5 from './assets/img/goldenpapaya/gp5.webp'
import GP6 from './assets/img/goldenpapaya/gp6.webp'

const useStyles = makeStyles(theme => ({
  item: {
    [theme.breakpoints.down('sm')]: {
      flexFlow:'column'
    },
    [theme.breakpoints.up('md')]: {
      flexFlow:'column'
    },
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  carousel: {
    [theme.breakpoints.down('sm')]: {
      height:'50vw',
      width:'90vw'
    },
    [theme.breakpoints.up('md')]: {
      height:'35vw',
      width:'60vw'
    },
    display:'flex',
    flexFlow:'column',
    justifyContent:'center',
    alignItems:'center'
  }
}));

export default function Buds(props) {
  const classes = useStyles();
  const [autoplay, setAutoplay] = React.useState(false);
  const [itemId, setItemId] = React.useState("");
  const theme = useTheme();
  let media = useMediaQuery(theme.breakpoints.up('sm'));

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    centerMode:true,
    variableWidth:true
  };

  const carouselExample = () => {
    return (
      <div className={classes.item}>
        <GridContainer className={classes.carousel}>
          <GridItem >
            <Card carousel>
              <Carousel {...settings}>
                <div>
                  <img src={goldenpapaya} alt='...'
                    style={{height:'50vw'}}/>
                </div>
                <div>
                  <img src={GP1} alt='...'
                    style={{height:'50vw'}}/>
                </div>
                <div>
                  <img src={GP2} alt='...'
                    style={{height:'50vw'}}/>
                </div>
                <div>
                <img src={GP3} alt='...'
                  style={{height:'50vw'}}/>
                </div>
                <div>
                  <img src={GP4} alt='...'
                    style={{height:'50vw'}}/>
                </div>
                <div>
                  <img src={GP5} alt='...'
                    style={{height:'50vw'}}/>
                </div>
                <div>
                  <img src={GP6} alt='...'
                    style={{height:'50vw'}}/>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }

  return (
    <div>
      <Header verified={props.verified} style={{height:'15vh'}}/>
      <Footer verified={props.verified}/>
      <div style={{flex:1}}>
        <Typography align='center' style={{fontFamily:'Jokerman-custom',fontSize:'2vw',
          flex:1,color:'#00DFAF'}}>
          click on dispensary logos to see what's in stock now
        </Typography>
      </div>
      <div style={{height:"70vh"}}>
        <Map />
      </div>
      <Typography align='center' style={{fontFamily:'Jokerman-custom',fontSize:'2vw',color:'#00DFAF',zIndex:"11"}}>
        <a href="https://southshorebuds.com/" target="_blank">Click here to order from Bud Bus for same-day delivery</a>
      </Typography>
    </div>
  );
}




// class Buds extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       verified: true
//     };
//   }
//
//   // when component mounts, first thing it does is fetch all existing data in our db
//   // then we incorporate a polling logic so that we can easily see if our db has
//   // changed and implement those changes into our UI
//   componentDidMount() {
//     this.renderRedirect();
//   }
//
//   renderRedirect = () => {
//     const cookies = new Cookies();
//     if (cookies.get('verified') !== 'true') {
//       this.setState(
//         {verified: false}
//       )
//     }
//   }
//
//   setCookieTrue() {
//     const cookies = new Cookies();
//     cookies.set('verified', 'true', { path: '/' })
//     this.setState(
//       {verified: true}
//     )
//     console.log("here")
//   }
//
//   render(){
//     return (
//       <div style={{backgroundColor:"#ffffff",height:'70vh',width:'100vw',
//         display:'flex',flexDirection:'column',
//         alignItems:'center',justifyContent:'center'}}>
//         <Fade in={true} timeout={1000}>
//           <div>
//             <div style={{height:'10vh',display:'flex',alignItems:'center',
//             justifyContent:'center',fontSize:'2vw'}}>
//               Buds are coming soon!!! Find them only in select dispensaries, starting January 2022
//             </div>
//             <div style={{height:'60vh',display:'flex',alignSelf:'center',
//             justifyContent:'center'}}>
//               <img src={VegPlantsPhoto} alt='...'/>
//             </div>
//           </div>
//         </Fade>
//       </div>
//     );
//   }
// }
//
// export default Buds;
