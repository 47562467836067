import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { sendSearch } from '../actions'
import { store } from '../index'
import axios from 'axios'
import {BACKEND_IP} from '../constants/backendIp'
import Card from '../stores/Card'
import ConfusedFace from '../components/animations/ConfusedFace'
import FlyingSearch from '../components/animations/FlyingSearch'
import SearchingAnim from '../components/animations/Searching'
import Grid from '@material-ui/core/Grid'

function Search(props) {
  const [searchContent, setSearchContent] = useState(
    <div style={{display:'flex',flexFlow:'column',alignItems:'center'}}>
      <div>Go ahead, search something</div>
      <FlyingSearch />
    </div>
  )

  useEffect(() => {
    if (props.sentSearch !== "") {
      document.getElementById("searchBar").value = props.sentSearch
      enterPressed(props.sentSearch)
      store.dispatch(props.sendSearch(""))
    }
  })

  const renderChildren = (resultList) => {
    if (resultList.length === 0){
      return (
        <div style={{display:'flex',flexFlow:'column',alignItems:'center'}}>
          <div>No Results Found</div>
          <ConfusedFace />
          <div>Please refine your search</div>
        </div>
      )
    } else {
      return resultList.map((item, key) => {
        if (item !== null) {
         console.log(item)
         return (
           <Grid key={item.itemId} item>
             <Card
               itemId={item._id}
               title={item.title}
               price={item.price}
               pic={item._id}
               description={item.description}
               weights={item.weights}
               sizes={item.sizes}
             />
           </Grid>
         );
       }
     })
    }
  }

  const enterPressed = (value) => {
    console.log(value)
    setSearchContent(
      <div style={{display:'flex',flexFlow:'column',alignItems:'center'}}>
        <SearchingAnim />
        <div>Searching...</div>
      </div>
    )
    axios.post('http://'+BACKEND_IP+'/api/search/searchItems', {
      search: value
    })
    .then((response) => {
      console.log(response);
      if (response.data.success) {
        setSearchContent(renderChildren(response.data.results))
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const keyPressed = (e) => {
    if (e.which === 13) {
      enterPressed(e.target.value)
    }
  }

  return (
    <div style={{ backgroundColor: "#2d2f2d",paddingTop:80}}>
        <div style={{
          display: 'flex', flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white', fontSize: '22px' }}>
          <div style={{fontSize:'46px',paddingBottom:40}}>Search</div>
          <input id="searchBar"
          style={{color:'black'}}
            onKeyDown={e => keyPressed(e)}
            placeholder="bud..."/>
          <div style={{ paddingTop:20}}>
          <div style={{height:40,width:'100vw',backgroundColor:'black'}} />
          <div style={{backgroundColor:'#2d2f2d',
            minHeight:'80vh'}}>
            <div style={{ display:'flex',
              flexDirection:'row', justifyContent:'space-around',
              flexWrap:'wrap', padding: '5vh'}}>
              {searchContent}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => (
  {
    sentSearch: state.sentSearch,
  }
);

export default withRouter(connect(mapStateToProps, { sendSearch })(Search));
