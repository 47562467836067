import React, { Component } from 'react'
import Main from './Main'
import DrawerMenu from './drawer/DrawerMenu'
import Header from './Header'
import Footer from './Footer'
import { Link, NavLink } from 'react-router-dom'
import Cookies from 'universal-cookie'
import TitleParticles from './components/animations/TitleParticles'
import baileysbudhouse from './assets/img/baileysbudhouse.webp'
import TowerView from './assets/img/towerview.webp'
import GrafitiView from './assets/img/grafitiview.webp'
import DamView from './assets/img/damview.webp'
import DamBuildingView from './assets/img/damandbuilding.webp'
import { Button, ButtonGroup, Fade } from "@material-ui/core";
import MillPhoto from './assets/img/rainbowmill.jpg'
import Logosubscribe from './components/CustomDialog/LogoSubscribe'
import InstagramIcon from './assets/img/Instagram-Logo.webp'
import Carousel from "react-slick"
import Typography from '@material-ui/core/typography'
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AddIcon from '@material-ui/icons/Add'
import GridContainer from "./components/Grid/GridContainer"
import GridItem from "./components/Grid/GridItem"
import Card from "./components/Card/Card"

const useStyles = makeStyles(theme => ({
  item: {
    [theme.breakpoints.down('sm')]: {
      flexFlow:'column'
    },
    [theme.breakpoints.up('md')]: {
      flexFlow:'column'
    },
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  carousel: {
    [theme.breakpoints.down('sm')]: {
      height:'50vh',
      width:'50vh'
    },
    [theme.breakpoints.up('md')]: {
      height:'50vh',
      width:'50vh'
    },
    display:'flex',
    flexFlow:'column',
    justifyContent:'center',
    alignItems:'center'
  }
}));

export default function Mill(props) {
  const classes = useStyles();
  const [autoplay, setAutoplay] = React.useState(false);
  const [itemId, setItemId] = React.useState("");
  const theme = useTheme();
  let media = useMediaQuery(theme.breakpoints.up('sm'));

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  const carouselExample = () => {
    return (
      <div className={classes.item}>
        <GridContainer className={classes.carousel}>
          <GridItem >
            <Card carousel>
              <Carousel {...settings}>
                <div>
                  <img src={MillPhoto} alt='...'
                    style={{height:'50vh',width:'50vh'}}/>
                </div>
                <div>
                  <img src={TowerView} alt='...'
                    style={{height:'50vh',width:'50vh'}}/>
                </div>
                <div>
                <img src={DamView} alt='...'
                  style={{height:'50vh',width:'50vh'}}/>
                </div>
                <div>
                  <img src={DamBuildingView} alt='...'
                    style={{height:'50vh',width:'50vh'}}/>
                </div>
                <div>
                  <img src={GrafitiView} alt='...'
                    style={{height:'50vh',width:'50vh'}}/>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }

  return (
    <div>
      <Header verified={props.verified} style={{height:'15vh'}}/>
      <Footer verified={props.verified}/>
      <Typography align='center' style={{width:'100%',fontSize:'2vw',fontFamily:'Alfa Slab One'}}>
        We bought a dilapidated mill & took it down to the studs to create a state of the art weed factory.
      </Typography>
      <Typography align='center' style={{width:'100%',fontSize:'2vw',fontFamily:'Alfa Slab One'}}>
        Built in 1900, it replaced a previous mill on the site that burned down.  With top notch sprinkler systems installed, that won’t happen again, but the mill will soon be facilitating a different kind of burning.
      </Typography>
      {carouselExample()}
      <Typography align='center' style={{width:'100%',fontSize:'2vw',fontFamily:'Alfa Slab One'}}>
        Overlooking scenic Beaver Brook in Dracut, Massachusetts there’s no better place to grow!
      </Typography>
    </div>
  );
}


// <Typography nowrap='false' style={{width:'75vw',fontSize:'2vw',fontFamily:'Alfa Slab One'}}>
//   Overlooking scenic Beaver Brook in Dracut, Massachusetts there’s no better place to grow!
// </Typography>
// class Mill extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       verified: true
//     };
//   }
//
//   // when component mounts, first thing it does is fetch all existing data in our db
//   // then we incorporate a polling logic so that we can easily see if our db has
//   // changed and implement those changes into our UI
//   componentDidMount() {
//     this.renderRedirect();
//   }
//
//   renderRedirect = () => {
//     const cookies = new Cookies();
//     if (cookies.get('verified') !== 'true') {
//       this.setState(
//         {verified: false}
//       )
//     }
//   }
//
//   setCookieTrue() {
//     const cookies = new Cookies();
//     cookies.set('verified', 'true', { path: '/' })
//     this.setState(
//       {verified: true}
//     )
//     console.log("here")
//   }
//
//   render(){
//     return (
//       <div style={{backgroundColor:"#ffffff",height:'70vh',width:'100vw',
//         display:'flex',flexDirection:'column',
//         alignItems:'center',justifyContent:'center'}}>
//         <Fade in={true} timeout={1000}>
//           <img src={MillPhoto} alt='...'
//             style={{height:'60vh',marginTop:'10vh'}}/>
//         </Fade>
//       </div>
//     );
//   }
// }
//
// export default Mill;
