import React from "react";

export default function AddAnimation(props) {
  return (
    <lottie-player
        src="https://assets8.lottiefiles.com/packages/lf20_tf6wSv.json"
        background="transparent"  speed="1"
        style={{width: '200px', height: '200px'}}  loop autoplay >
    </lottie-player>
  );
}
