import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import Typography from '@material-ui/core/Typography'

export default function Logosubscribe() {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState("")

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div  style={{
      display: 'flex', flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'}}>
      <div style={{zIndex: 1, textDecoration: 'none'}}>
        <div id="contact" style={{color:'#2d2f2d',fontWeight:'800',
          fontSize:'2vw',fontFamily:'Bungee Shade'}}>
          <a href="https://forms.gle/ugKWrTWeGUsaTh2r7" target="_blank">Enlist</a>
        </div>
      </div>

      <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{fontSize:20,color:'black'}}>
            Get important news, no spam
          </DialogContentText>
          <DialogContentText style={{fontSize:20,color:'black'}}>
            sent to you via text from Philip Bailey
          </DialogContentText>
          <DialogContentText style={{fontSize:20,color:'black'}}>
            click <a href="https://forms.gle/ugKWrTWeGUsaTh2r7" target="_blank">this link</a> and fill out the google form to get on the list
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            <Typography style={{fontSize:14}}>Cancel</Typography>
          </Button>
          <a href="https://forms.gle/ugKWrTWeGUsaTh2r7" target="_blank">
          <Button
            color="primary"
          >
            <Typography style={{fontSize:14}}>Enlist</Typography>
          </Button>
          </a>
        </DialogActions>
        </Dialog>

    </div>
  );
}
