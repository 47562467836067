import React, { useEffect } from 'react'
import Main from './Main'
import DrawerMenu from './drawer/DrawerMenu'
import Header from './Header'
import Footer from './Footer'
import DutchieScript from './hooks/DutchieScript.js'
import Typography from '@material-ui/core/typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  baileysbuds: {
    [theme.breakpoints.down('sm')]: {
      fontSize:'10vw'
    },
    [theme.breakpoints.up('md')]: {
      fontSize:'4vw'
    },
    fontFamily:'Jokerman-custom',
    flex:1,
    color:'#CACCE7'
  },
  townlist: {
    [theme.breakpoints.down('sm')]: {
      fontSize:'5vw'
    },
    [theme.breakpoints.up('md')]: {
      fontSize:'2vw'
    },
    fontFamily:'Jokerman-custom',
    flex:1,
    color:'#CAE7D7'
  }
}));

export default function Menu(props) {
  const classes = useStyles();
  useEffect(() => {
  const divis = document.createElement('div');
  divis.id = "menu";
  const script = document.createElement('script');
  script.src = "https://dutchie.com/api/v2/embedded-menu/642197be30007a004d9ecf13.js";
  script.async = "";
  script.id = "dutchie--embed__script";

  document.body.appendChild(divis);
  divis.appendChild(script);

  console.log(props.verified)

  return () => {
    document.body.removeChild(divis);
  }
  }, [props.verified])

  return (
    <div>
      <Header verified={props.verified} style={{height:'15vh'}}/>
      <Footer verified={props.verified}/>
      <Typography className={classes.baileysbuds} align='center'>
        Bailey's Buds Bus
      </Typography>
      <Typography className={classes.townlist} align='center'>
        Boston, Watertown, Cambridge, Somerville, Newton, Waltham, Belmont, Arlington, Brookline, Chelsea, Quincy, Rockland, Abington, Kingston, Plymouth, Marshfield
      </Typography>
    </div>
  );
}




// class Buds extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       verified: true
//     };
//   }
//
//   // when component mounts, first thing it does is fetch all existing data in our db
//   // then we incorporate a polling logic so that we can easily see if our db has
//   // changed and implement those changes into our UI
//   componentDidMount() {
//     this.renderRedirect();
//   }
//
//   renderRedirect = () => {
//     const cookies = new Cookies();
//     if (cookies.get('verified') !== 'true') {
//       this.setState(
//         {verified: false}
//       )
//     }
//   }
//
//   setCookieTrue() {
//     const cookies = new Cookies();
//     cookies.set('verified', 'true', { path: '/' })
//     this.setState(
//       {verified: true}
//     )
//     console.log("here")
//   }
//
//   render(){
//     return (
//       <div style={{backgroundColor:"#ffffff",height:'70vh',width:'100vw',
//         display:'flex',flexDirection:'column',
//         alignItems:'center',justifyContent:'center'}}>
//         <Fade in={true} timeout={1000}>
//           <div>
//             <div style={{height:'10vh',display:'flex',alignItems:'center',
//             justifyContent:'center',fontSize:'2vw'}}>
//               Buds are coming soon!!! Find them only in select dispensaries, starting January 2022
//             </div>
//             <div style={{height:'60vh',display:'flex',alignSelf:'center',
//             justifyContent:'center'}}>
//               <img src={VegPlantsPhoto} alt='...'/>
//             </div>
//           </div>
//         </Fade>
//       </div>
//     );
//   }
// }
//
// export default Buds;
