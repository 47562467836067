import React from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AccountIcon from '@material-ui/icons/AccountCircleTwoTone'
import SettingsIcon from '@material-ui/icons/SettingsTwoTone'
import HelpIcon from '@material-ui/icons/HelpTwoTone'

const useStyles = makeStyles(theme => ({
  personal: {
    backgroundColor:'#1b5e20',
    minWidth: 240,
    width: 240
  },
  help: {
    backgroundColor:'#4caf50',
    minWidth: 240,
    width: 240
  },
  heading: {
    fontWeight:'bold',
    fontSize: 16,
    fontFamily:'Raleway',
    textDecoration: 'underline'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontFamily:'Raleway'
  },
  link: {
    marginLeft: 5,
    fontSize:15,
    fontFamily: 'Raleway'
  },
}));

export default function InfoPanel() {
  const classes = useStyles();
  const [personalExpanded, setPersonalExpanded] = React.useState(true)
  const [helpExpanded, setHelpExpanded] = React.useState(true)
  const menuItem = {display:'flex',flexFlow:'row',marginTop:'20px',
    fontSize:18,color:'black',textDecoration:'none'};
  const activeMenuItem = {display:'flex',flexFlow:'row',marginTop:'20px',
    fontSize:18,fontWeight:'bold',color:'black',textDecoration:'none'};

  const handlePersonalChange = () => {
    if (personalExpanded) {
      setPersonalExpanded(false)
    } else {
      setPersonalExpanded(true)
    }
  };

  const handleHelpChange = () => {
    if (helpExpanded) {
      setHelpExpanded(false)
    } else {
      setHelpExpanded(true)
    }
  };

  return (
    <div>
      <ExpansionPanel className={classes.personal}
        expanded={personalExpanded}
        onChange={handlePersonalChange}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>My Account</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display:'flex',flexFlow:'column'}}>
          <NavLink to='/info/settings' href="#top"
            activeStyle={activeMenuItem}
            style={menuItem}>
            <SettingsIcon />
            <div className={classes.link}>
              Settings
            </div>
          </NavLink>
          <NavLink to='/info/account' href="#top"
            activeStyle={activeMenuItem}
            style={menuItem}>
            <AccountIcon />
            <div className={classes.link}>
              Account
            </div>
          </NavLink>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel className={classes.help}
        expanded={helpExpanded}
        onChange={handleHelpChange}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Help</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display:'flex',flexFlow:'column'}}>
          <NavLink exact to='/info/help' href="#top"
            activeStyle={activeMenuItem}
            style={menuItem}>
            <HelpIcon />
            <div className={classes.link}>
              How this works
            </div>
          </NavLink>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
