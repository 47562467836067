import React, { Component } from 'react'
import Main from '../Main'
import DrawerMenu from '../drawer/DrawerMenu'
import Footer from '../Footer'
import { Link, NavLink } from 'react-router-dom'
import Cookies from 'universal-cookie'
import TitleParticles from '../components/animations/TitleParticles'
import baileysbudhouse from '../assets/img/baileysbudhouse.png'
import { Button, ButtonGroup, Fade } from "@material-ui/core"
import WinteryDam from '../assets/img/winteryDam.webp'
import IMG1 from '../assets/img/mojo/mojo1.webp'
import IMG2 from '../assets/img/mojo/mojo2.webp'
import IMG3 from '../assets/img/mojo/mojo3.webp'
import IMG4 from '../assets/img/mojo/mojo4.webp'
import IMG5 from '../assets/img/mojo/mojo5.webp'
import IMG6 from '../assets/img/mojo/mojo6.webp'
import IMG7 from '../assets/img/mojo/mojo7.webp'
import Logosubscribe from '../components/CustomDialog/LogoSubscribe'
import InstagramIcon from '../assets/img/Instagram-Logo.png'
import Carousel from "react-slick"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from '@material-ui/core/typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AddIcon from '@material-ui/icons/Add'
import GridContainer from "../components/Grid/GridContainer"
import GridItem from "../components/Grid/GridItem"
import Card from "../components/Card/Card"
import goldenpapaya from "../assets/img/goldenpapaya.webp"

const useStyles = makeStyles(theme => ({
  item: {
    [theme.breakpoints.down('sm')]: {
      flexFlow:'column'
    },
    [theme.breakpoints.up('md')]: {
      flexFlow:'column'
    },
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  carousel: {
    [theme.breakpoints.down('sm')]: {
      height:'65vw',
      width:'90vw'
    },
    [theme.breakpoints.up('md')]: {
      height:'50vw',
      width:'90vw'
    },
    display:'flex',
    flexFlow:'column',
    justifyContent:'center',
    alignItems:'center'
  }
}));

export default function Mojo(props) {
  const classes = useStyles();
  const [autoplay, setAutoplay] = React.useState(false);
  const [itemId, setItemId] = React.useState("");
  const theme = useTheme();
  let media = useMediaQuery(theme.breakpoints.up('sm'));

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    centerMode:true,
    variableWidth:true
  };

  const carouselExample = () => {
    return (
      <div className={classes.item}>
        <GridContainer className={classes.carousel}>
          <GridItem >
            <Card carousel>
              <Carousel {...settings}>
                <div>
                  <img src={IMG1} alt='...'
                    style={{height:'50vw'}}/>
                </div>
                <div>
                  <img src={IMG2} alt='...'
                    style={{height:'50vw'}}/>
                </div>
                <div>
                <img src={IMG3} alt='...'
                  style={{height:'50vw'}}/>
                </div>
                <div>
                  <img src={IMG4} alt='...'
                    style={{height:'50vw'}}/>
                </div>
                <div>
                  <img src={IMG5} alt='...'
                    style={{height:'50vw'}}/>
                </div>
                <div>
                  <img src={IMG6} alt='...'
                    style={{height:'50vw'}}/>
                </div>
                <div>
                  <img src={IMG7} alt='...'
                    style={{height:'50vw'}}/>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }

  return (

      <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignSelf:'center',alignItems:'center'}}>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignSelf:'center'}}>
        <Typography style={{fontSize:'8vw',fontFamily:'Bungee Shade',
          justifyContent:'center',alignSelf:'center',color:'#FF00FF'}}>
          Mojo
        </Typography>
        </div>
        <Typography style={{fontSize:'4vw',fontFamily:'Calibri',
          justifyContent:'center',alignSelf:'center',textAlign:'center',color:'#000000'}}>
          Slurriskunk x (platinum Kush breath x lust)
        </Typography>
        <div>
          {carouselExample()}
        </div>
        <Typography style={{fontSize:'4vw',fontFamily:'Calibri',
          justifyContent:'center',alignSelf:'center',textAlign:'center',color:'#000000'}}>
          If you’ve lost your mojo there’s only 1 thing that will restore it: Travel back to 1969 with this energizing sativa leaning hybrid.
          With a particularly floral bud structure & stank & orange pistils that comprise a shagadelic carpet of chest hair this strain is sure to help you regain your mojo!
        </Typography>
        <div style={{display:'flex',
          justifyContent:'center',alignItems:'center'}}>
          <iframe src="https://open.spotify.com/embed/playlist/0rJ2HtwiWd3vk8FaxZU8On?utm_source=generator&theme=0" width="300" height="380" frameborder="0" allowtransparency="true" allow="encrypted-media">
          </iframe>
        </div>
        <Typography style={{fontSize:'3.5vw',fontFamily:'Calibri',
          justifyContent:'center',alignSelf:'center',color:'#000000'}}>
           Bailey’s Buds is a social equity, family-owned Microbusiness up in Dracut.  Our growing guru, Gus Bailey, had grow ops in Waltham & Boston until he got busted & served 6 years in the slammer.  When he was released from prison, the family pooled our resources together to start this endeavor & after a long slog we are so happy to finally be selling weed legally!  We bought a dilapidated mill built in 1900 & took it down to the studs to build a top notch weed factory.  We grow in living soil with no chemical fertilizers or pesticides.
        </Typography>
        <img src={WinteryDam} style={{width:'80VW',display:'flex'}}/>

      </div>
  );
}
