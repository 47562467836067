import React, { Component } from 'react'
import Main from './Main'
import DrawerMenu from './drawer/DrawerMenu'
import Footer from './Footer'
import { Link, NavLink } from 'react-router-dom'
import Cookies from 'universal-cookie'
import TitleParticles from './components/animations/TitleParticles'
import baileysbudhouse from './assets/img/baileysbudhouse.webp'
import TowerView from './assets/img/towerview.webp'
import GrafitiView from './assets/img/grafitiview.webp'
import DamView from './assets/img/damview.webp'
import DamBuildingView from './assets/img/damandbuilding.webp'
import { Button, ButtonGroup, Fade } from "@material-ui/core";
import MillPhoto from './assets/img/rainbowmill.jpg'
import Logosubscribe from './components/CustomDialog/LogoSubscribe'
import InstagramIcon from './assets/img/Instagram-Logo.webp'
import Carousel from "react-slick"
import Typography from '@material-ui/core/typography'
import { makeStyles, useTheme } from "@material-ui/core/styles"
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AddIcon from '@material-ui/icons/Add'
import GridContainer from "./components/Grid/GridContainer"
import GridItem from "./components/Grid/GridItem"
import Card from "./components/Card/Card"

const useStyles = makeStyles(theme => ({
  item: {
    [theme.breakpoints.down('sm')]: {
      flexFlow:'column'
    },
    [theme.breakpoints.up('md')]: {
      flexFlow:'column'
    },
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  carousel: {
    [theme.breakpoints.down('sm')]: {
      height:'50vh',
      width:'50vh'
    },
    [theme.breakpoints.up('md')]: {
      height:'50vh',
      width:'50vh'
    },
    display:'flex',
    flexFlow:'column',
    justifyContent:'center',
    alignItems:'center'
  }
}));

export default function Learn(props) {
  const classes = useStyles();
  const [autoplay, setAutoplay] = React.useState(false);
  const [itemId, setItemId] = React.useState("");
  const theme = useTheme();
  let media = useMediaQuery(theme.breakpoints.up('sm'));

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  return (
    <div style={{display:'flex',
      justifyContent:'center',
      alignItems:'center',flexFlow:'column'}}>
      <Typography style={{width:'75vw',fontSize:'2vw',fontFamily:'Alfa Slab One'}}>
        Drugs are bad mmkay?
      </Typography>
    </div>
  );
}
