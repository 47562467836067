import { ActionTypes } from '../actions';

const MenuReducer = (state = true, action) => {
  switch (action.type) {
    case ActionTypes.MENUBUTTON:
      return action.payload;
    default:
      return state;
  }
};

export default MenuReducer;
