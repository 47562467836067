import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {BACKEND_IP} from '../constants/backendIp';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Card,CardHeader,Button,CardContent,CardActions,Collapse,
  Dialog,DialogActions,DialogContent,DialogContentText,
  DialogTitle,Slide,MenuItem,FormHelperText,FormControl,Select,
  IconButton,Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddToCartDialog from './AddToCartDialog';

const useStyles = makeStyles(theme => ({
  card: {
    [theme.breakpoints.down('sm')]: {
      width: '30vw',
    },
    [theme.breakpoints.up('md')]: {
      width: '20vw',
    },
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    flexFlow:'column',
    paddingBottom: '25px',
  },
  price: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '9px',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '12px',
    },
    fontFamily: 'Raleway',
    color:'#2d2f2d',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


export default function ProductCard(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  var [dialog, setDialog] = React.useState(<div/>);

  const handleClickOpen = () => {
    setOpen(true)
    setTimeout(() => {
        setOpen(false)
    }, 100);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={classes.card}>
      <Link to={'/item/'+props.pic} href="#top" style={{ textDecoration: 'none',
        color:'black',display:'flex',justifyContent:'center',alignItems:'center',
        flexFlow:'column'}}>
        <h4 style={{fontFamily:'Raleway',fontSize:'16px',color:'white',
          textShadow: '0px 0px 4px  #e2d799'}}>
          {props.title}
        </h4>

        <img
          src={"http://"+BACKEND_IP+"/"+props.pic}
          alt="..."
          style={{borderRadius: "50%",boxShadow:
          "0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
          width:"35vw",height:"35vw",maxWidth:"200px",maxHeight:"200px"}}/>
      </Link>
      <div style={{backgroundColor:'white',borderRadius:'15px',marginTop:'15px',
        width:'35vw',maxWidth:'180px',opacity:0.8}}>
        <CardActions disableSpacing style={{display:'flex',
          justifyContent:'space-between',height:'20px',
          marginTop:'10px',marginBottom:'10px'}}>
          <IconButton aria-label="add to favorites" onClick={handleClickOpen}>
            <AddIcon />
          </IconButton>
          <div className={classes.price}>
            <div>{props.price}</div>
            <div>{props.sizes[0]}</div>
          </div>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>THC content:</Typography>
            <Typography paragraph>
              {props.description}
            </Typography>
            <Typography>
              Some CBD too maybe.
            </Typography>
          </CardContent>
        </Collapse>
      </div>
      <AddToCartDialog
        itemId={props.itemId}
        title={props.title}
        pic={props.pic}
        price={props.price}
        weights={props.weights}
        sizes={props.sizes}
        isOpen={open}
      />
    </div>
  );
}
