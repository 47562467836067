import { combineReducers } from 'redux'
import AuthReducer from './auth-reducer'
import SearchReducer from './search-reducer'
import SendSearchReducer from './send-search-reducer'
import CartReducer from './cart-reducer'
import AnimateCartReducer from './animate-cart-reducer'
import ProfileReducer from './profile-reducer'
import MenuReducer from './menu-reducer'

/**
 * combineReducers is important to understand. As your app might grow in size
 * and complexity, you will likely begin to split your reducers into separate
 * functions - with each one managing a separate slice of the state! This helper
 * function from 'redux' simply merges the reducers. Keep in mind we are using
 * the ES6 shorthand for property notation.
 *
 * If you're transitioning from Flux, you will notice we only use one store, but
 * instead of relying on multiple stores to manage diff parts of the state, we use
 * various reducers and combine them.
 *
 * More info: http://rackt.org/redux/docs/api/combineReducers.html
 */
const rootReducer = combineReducers({
  auth: AuthReducer,
  search: SearchReducer,
  sentSearch: SendSearchReducer,
  cart: CartReducer,
  animCart: AnimateCartReducer,
  profile: ProfileReducer,
  menu: MenuReducer,
});

export default rootReducer;
