import React, { Component } from 'react';
import axios from 'axios';
import {BACKEND_IP} from '../constants/backendIp';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from './Card';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      {children}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function renderTabs(props) {
  let tabWidth = '20vw';
  let tabDisplay = 'block';
  if (props.categories.length === 4) {
    tabWidth = '20vw';
  } else if (props.categories.length === 3) {
      tabWidth = '33vw';
  } else if (props.categories.length === 2) {
      tabWidth = '50vw';
  } else if (props.categories.length < 2) {
      tabDisplay = 'none';
  }
  return props.categories.map((category, key) => {
    return (
      <Tab key={key} style={{display:tabDisplay, width:tabWidth}}
        label={category.category} {...a11yProps(key)} />
    )
  })
}

function renderMenus(props, value) {
  return props.categories.map((category, key) => {
    return (
      <TabPanel key={key} value={value} index={key}>
          <Grid item xs={12} style={{marginTop:'10px'}}>
            <Grid container justify="center" >
              <div style={{flex:1,overflow:'auto',
                backgroundImage:'/images/seedling.jpg'}}>
              <RenderChildren itemIds={category.items}/>
              </div>
            </Grid>
          </Grid>
      </TabPanel>
    )
  })
}

export default function ScrollableTabsButtonAuto(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <AppBar position="sticky" color="default" style={{zIndex:0}}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {renderTabs(props)}
        </Tabs>
      </AppBar>
      <div>{renderMenus(props, value)}</div>
    </div>
  );
}

class RenderChildren extends Component {
  constructor() {
    super()
    this.state = {
      items: new Array(0),
      itemsFromProps: null
    }
  }

  componentDidMount() {
    this.setState({itemsFromProps:this.props.itemIds});
    this.getItems();
  }

  componentDidUpdate() {
    if (this.props.itemIds !== this.state.itemsFromProps) {
        this.setState({
          items: new Array(0),
          itemsFromProps:this.props.itemIds
        });
        this.getItems();
    }
  }

  getItems = () => {
    if (this.props.itemIds){;
      this.setState({
        items: this.props.itemIds
      });
    }
  }

  renderChildren = () => {
    if (this.state.items !== undefined) {
      return this.state.items.map((item, key) => {
        if (item !== null) {
          return (
            <Grid key={item.itemId} item>
              <Card
                itemId={item._id}
                title={item.title}
                price={item.price}
                pic={item.pic}
                description={item.description}
                weights={item.weights}
                sizes={item.sizes}
              />
            </Grid>
          );
        }
      })
    }
  }

  render() {
    return (
      <div style={{backgroundColor:'#2d2f2d',
        minHeight:'80vh'}}>
        <div style={{ display:'flex',
          flexDirection:'row', justifyContent:'space-around',
          flexWrap:'wrap', padding: '5vh'}}>
          {this.renderChildren()}
        </div>
      </div>
    );
  }
}
