import React from 'react'
import Construction from '../../components/animations/Construction'

export default function Help(props) {


  return (
    <Construction />
  )
}
