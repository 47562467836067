import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function QuantitySelector(props) {
  const classes = useStyles();
  const [quantity, setQuantity] = React.useState(-1);

  const handleChange = event => {
    let value = event.target.value
    setQuantity(value)
    let index = props.weights.indexOf(value)
    props.setQuantity(props.sizes[index])
  }

  function showValues() {
    if (props.weights !== undefined) {
      if (quantity === -1) {
        setQuantity(props.weights[0])
        props.setQuantity(props.sizes[0])
      }
      let rows = [];
      for (let i=0;i<props.sizes.length;i++) {
        rows.push(
          <MenuItem value={props.weights[i]}>
            {props.sizes[i]}
          </MenuItem>
        )
      }
      return (
        <Select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          value={quantity}
          onChange={handleChange}
          displayEmpty
          className={classes.selectEmpty}
        >
          {rows}
        </Select>
      )
    }
  }

  return (
    <FormControl className={classes.formControl}>
      {showValues()}
      <FormHelperText>{props.label}</FormHelperText>
    </FormControl>
  )
}
