import React from "react";

export default function ConfusedFace(props) {
  return (
    <lottie-player
        src="https://assets3.lottiefiles.com/datafiles/S89j9kVV9KN43jn/data.json"
        background="transparent"  speed="1"
        style={{width: '200px', height: '200px'}}  loop autoplay >
    </lottie-player>
  );
}
