import React from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CartIcon from '@material-ui/icons/ShoppingCartTwoTone'
import FavoriteIcon from '@material-ui/icons/FavoriteTwoTone'
import NotifyIcon from '@material-ui/icons/NotificationImportantTwoTone'
import CheckoutIcon from '@material-ui/icons/ReceiptTwoTone'
import PaymentIcon from '@material-ui/icons/PaymentTwoTone'
import HistoryIcon from '@material-ui/icons/HistoryTwoTone'
import RegistrationIcon from '@material-ui/icons/CreateTwoTone';



const useStyles = makeStyles(theme => ({
  personal: {
    backgroundColor:'#1b5e20',
    minWidth: 240,
    width: 240
  },
  payment: {
    backgroundColor:'#4caf50',
    minWidth: 240,
    width: 240
  },
  history: {
    backgroundColor:'#a5d6a7',
    minWidth: 240,
    width: 240
  },
  heading: {
    fontWeight:'bold',
    fontSize: 16,
    fontFamily:'Raleway',
    textDecoration: 'underline'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontFamily:'Raleway'
  },
  link: {
    marginLeft: 5,
    fontSize:15,
    fontFamily: 'Raleway'
  },
}));

export default function CartPanel() {
  const classes = useStyles();
  const [personalExpanded, setPersonalExpanded] = React.useState(true)
  const [paymentExpanded, setPaymentExpanded] = React.useState(true)
  const [historyExpanded, setHistoryExpanded] = React.useState(false)
  const menuItem = {display:'flex',flexFlow:'row',marginTop:'20px',
    fontSize:18,color:'black',textDecoration:'none'};
  const activeMenuItem = {display:'flex',flexFlow:'row',marginTop:'20px',
    fontSize:18,fontWeight:'bold',color:'black',textDecoration:'none'};

  const handlePersonalChange = () => {
    if (personalExpanded) {
      setPersonalExpanded(false)
    } else {
      setPersonalExpanded(true)
    }
  };

  const handlePaymentChange = () => {
    if (paymentExpanded) {
      setPaymentExpanded(false)
    } else {
      setPaymentExpanded(true)
    }
  };

  const handleHistoryChange = () => {
    if (historyExpanded) {
      setHistoryExpanded(false)
    } else {
      setHistoryExpanded(true)
    }
  };

  return (
    <div>
      <ExpansionPanel className={classes.personal}
        expanded={personalExpanded}
        onChange={handlePersonalChange}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>My Items</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display:'flex',flexFlow:'column'}}>
          <NavLink exact to='/cart/cart' href="#top"
            activeStyle={activeMenuItem}
            style={menuItem}>
            <CartIcon />
            <div className={classes.link}>
              Cart
            </div>
          </NavLink>
          <NavLink exact to='/cart/favorites' href="#top"
            activeStyle={activeMenuItem}
            style={menuItem}>
            <FavoriteIcon />
            <div className={classes.link}>
              Favorites
            </div>
          </NavLink>
          <NavLink exact to='/cart/notify' href="#top"
            activeStyle={activeMenuItem}
            style={menuItem}>
            <NotifyIcon />
            <div className={classes.link}>
              Notify When Available
            </div>
          </NavLink>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel className={classes.payment}
        expanded={paymentExpanded}
        onChange={handlePaymentChange}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Complete Purchase</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display:'flex',flexFlow:'column'}}>
          <NavLink to='/cart/checkout' href="#top"
            activeStyle={activeMenuItem}
            style={menuItem}>
            <CheckoutIcon />
            <div className={classes.link}>
              Checkout
            </div>
          </NavLink>
          <NavLink to='/cart/payment' href="#top"
            activeStyle={activeMenuItem}
            style={menuItem}>
            <PaymentIcon />
            <div className={classes.link}>
              Payment Options
            </div>
          </NavLink>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel className={classes.history}
        expanded={historyExpanded}
        onChange={handleHistoryChange}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>History</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display:'flex',flexFlow:'column'}}>
          <NavLink to='/cart/history' href="#top"
            activeStyle={activeMenuItem}
            style={menuItem}>
            <HistoryIcon />
            <div className={classes.link}>
              Previous Orders
            </div>
          </NavLink>
          <NavLink to='/cart/registration' href="#top"
            activeStyle={activeMenuItem}
            style={menuItem}>
            <RegistrationIcon />
            <div className={classes.link}>
              Registration
            </div>
          </NavLink>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
