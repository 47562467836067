import React from "react";

export default function GearLoading(props) {
  return (
    <lottie-player
        src="https://assets2.lottiefiles.com/packages/lf20_zJ7wi8.json"
        background="transparent"  speed="1"
        style={{width:'40px',height: '40px'}} loop autoplay >
    </lottie-player>
  );
}
