import React from "react";

export default function Cradle(props) {
  return (
    <lottie-player
        src="https://assets1.lottiefiles.com/packages/lf20_6HE7wm.json"  background="transparent"  speed="1"
        style={{width: '300px', height: '300px'}}  loop autoplay >
    </lottie-player>
  );
}
