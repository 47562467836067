import React from "react";

export default function LikeHeart(props) {
  return (
    <lottie-player
        src="https://assets9.lottiefiles.com/datafiles/IhSALigao8ZXm3t/data.json"
        background="transparent"  speed="0.25"
        style={{width: '40px', height: '40px'}}  autoplay >
    </lottie-player>
  );
}
