export const ActionTypes = {
  AUTHENTICATE: 'AUTHENTICATE',
  LOGOUT: 'LOGOUT',
  SEARCHBUTTON: 'SEARCHBUTTON',
  SENDSEARCH: 'SENDSEARCH',
  CARTBUTTON: 'CARTBUTTON',
  ANIMATECART: 'ANIMATECART',
  PROFILEBUTTON: 'PROFILEBUTTON',
  MENUBUTTON: 'MENUBUTTON',
};

export function authenticate(user) {
  return {
    type: ActionTypes.AUTHENTICATE,
    payload: user,
  }
}

export function logout() {
  return {
    type: ActionTypes.LOGOUT,
    payload: null,
  }
}

export function searchbutton(show) {
  return {
    type: ActionTypes.SEARCHBUTTON,
    payload: show,
  }
}

export function sendSearch(search) {
  return {
    type: ActionTypes.SENDSEARCH,
    payload: search,
  }
}

export function cartbutton(show) {
  return {
    type: ActionTypes.CARTBUTTON,
    payload: show,
  }
}

export function animateCart(state) {
  return {
    type: ActionTypes.ANIMATECART,
    payload: state,
  }
}

export function profilebutton(show) {
  return {
    type: ActionTypes.PROFILEBUTTON,
    payload: show,
  }
}

export function menubutton(show) {
  return {
    type: ActionTypes.MENUBUTTON,
    payload: show,
  }
}
