import React ,{ useState } from 'react'
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { sendSearch } from '../actions';
import { store } from '../index';
import Draggable from 'react-draggable'
import Fab from '@material-ui/core/Fab'
import { makeStyles } from "@material-ui/core/styles"
import SearchIcon from '@material-ui/icons/SearchTwoTone'
import ClearIcon from '@material-ui/icons/ClearTwoTone'

const useStyles = makeStyles(theme => ({
  searchbar: {
    [theme.breakpoints.down('xs')]: {
      maxWidth:'150px'
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth:'300px'
    },
  },
  container: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent:'flex-end'
  },
}));

function SearchButton(props) {
  const classes = useStyles()
  const [disabled, setDisabled] = useState(true)
  const [searchEntered, setSearchEntered] = useState(false)
  const [searchPhrase, setSearchPhrase] = useState("")

  const changeSearchbar = () => {
    if (disabled) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  const handleSearchChange = (e) => {
    // console.log(e.target.value)
    // // Variable to hold the original version of the list
    // let currentList = [];
    // // Variable to hold the filtered list before putting into state
    // let newList = [];
    //
    // // If the search bar isn't empty
    // if (e.target.value !== "") {
    //   // Assign the original list to currentList
    //   currentList = this.props.items;
    //
    //   // Use .filter() to determine which items should be displayed
    //   // based on the search terms
    //   newList = currentList.filter(item => {
    //     // change current item to lowercase
    //     const lc = item.toLowerCase();
    //     // change search term to lowercase
    //     const filter = e.target.value.toLowerCase();
    //     // check to see if the current list item includes the search term
    //     // If it does, it will be added to newList. Using lowercase eliminates
    //     // issues with capitalization in search terms and search content
    //     return lc.includes(filter);
    //   });
    // } else {
    //   // If the search bar is empty, set newList to original task list
    //   newList = this.props.items;
    // }
    // // Set the filtered state based on what our rules added to newList
    // this.setState({
    //   filtered: newList
    // });
  }

  const keyPressed = (e) => {
    if (e.which === 13) {
      // setSearchPhrase(e.target.value)
      store.dispatch(props.sendSearch(e.target.value))
      setSearchEntered(true)
      setTimeout(() => {
          setSearchEntered(false)
      }, 100);
    }
  }

  const displayIcon = () => {
    const iconStyle = {marginRight:220,marginTop:15,zIndex:10}
    if (disabled) {
      return(
        <SearchIcon fontSize='large' style={iconStyle}/>
      )
    } else {
      return(
        <ClearIcon fontSize='large' style={iconStyle}/>
      )
    }
  }

  if (searchEntered) {
    return(<Redirect to={'/search'} />)
  } else {
    return (
      <Draggable
        axis="both"
        handle=".handle"
        position={props.dragPosition}
        grid={[1, 1]}
        scale={1}
        onStart={props.handleStart}
        onDrag={props.handleDrag}
        onStop={props.handleStop}
        disabled={!disabled}
        style={{paddingRight:'5vw'}}>
        <Fab className="handle"
          disableFocusRipple={true}
          disableRipple={true}
          style={{zIndex:5,textDecoration:'none',
          margin: '1vw'}}>
          <div
            autocomplete="on"
            className={classes.container}>
            <input id="search"
              style={Object.assign({}, styles.button, !disabled && styles.buttonEnabled)}
              onKeyDown={e => keyPressed(e)}
              placeholder="bud..."/>
            <span onClick={changeSearchbar}
              style={Object.assign({}, styles.icon, !disabled && styles.enabledForIcon)}>
              {displayIcon()}
            </span>
          </div>
        </Fab>
      </Draggable>
    )
  }
}

const styles = {
  button: {
    width: 0,
    height: 50,
    zIndex: 0,
    border: 'none',
    borderRadius: 4,
    backgroundColor: '#e0e0e0',
    backgroundPosition: '0 center',
    marginLeft:220,
    fontSize: 20,
    cursor: 'pointer',
    transition: '.5s all',
  },
  buttonEnabled: {
    width: 150,
    backgroundPosition: '100% center',
  },
  icon: {
    transition: '.4s all',
  },
  enabledForIcon: {
    marginRight:150
  }
}

export default withRouter(connect(null, { sendSearch })(SearchButton));
