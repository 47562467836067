import React, { Component } from 'react'
import Main from './Main'
import DrawerMenu from './drawer/DrawerMenu'
import { Link, NavLink } from 'react-router-dom'
import Cookies from 'universal-cookie'
import TitleParticles from './components/animations/TitleParticles'
import baileysbudhouse from './assets/img/baileysbudhouse.png'
import { Button, ButtonGroup, Fade } from "@material-ui/core";
import TwentyOnePlusDialog from './components/CustomDialog/TwentyOnePlusDialog'
import Logosubscribe from './components/CustomDialog/LogoSubscribe'
import InstagramIcon from './assets/img/Instagram-Logo.png'
import ContactDialog from './components/CustomDialog/ContactDialog'
import './App.css'
import Typography from '@material-ui/core/typography'

export default function Footer(props) {

  return(
    <div style={{fontFamily:'Raleway',color:'#2d2f2d',fontSize:'10px'}}>
      <Fade in={props.verified} timeout={20000} style={{width:'100%'}}>
        <nav style={{
          display: 'flex', flexDirection: 'row',
          alignItems:'center',justifyContent:'center',alignSelf:'center'}}>
          <Button>
            <ContactDialog/>
          </Button>
          <Button style={{paddingLeft:'3vw',paddingRight:'3vw'}}>
            <a href="https://www.instagram.com/baileysbuds_/" target="_blank" rel="noopener noreferrer">
              <img src={InstagramIcon} alt='...'
                style={{height:'8vh'}}/>
            </a>
          </Button>
          <Button>
            <Logosubscribe/>
          </Button>
        </nav>
      </Fade>
    </div>
  );
}
