import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import Typography from '@material-ui/core/Typography'

export default function ContactDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div  style={{
      display: 'flex', flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'}}>
      <div style={{zIndex: 1, textDecoration: 'none'}}>
        <div id="contact" style={{color:'#2d2f2d',fontWeight:'800',
          fontSize:'2vw',fontFamily:'Bungee Shade'}}
        onClick={handleClickOpen}>
          Contact Us
        </div>
      </div>
      <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{fontSize:20,color:'black'}}>
            Please feel free to reach out to us by email or by phone
          </DialogContentText>
          <DialogContentText style={{fontSize:20,color:'black'}}>
            Email: info@baileysbuds.com
          </DialogContentText>
          <DialogContentText style={{fontSize:20,color:'black'}}>
            Phone: (978) 276-9024
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            <Typography style={{fontSize:14}}>Exit</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
