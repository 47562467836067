import React, { Component } from 'react';
import axios from 'axios';
import MenuTabs from './MenuTabs';
import {BACKEND_IP} from '../constants/backendIp';
import Spinner from "../components/Loading/Spinner";
import GreenSpin from "../components/Loading/GreenSpin";

class Store extends Component {
  constructor() {
    super()
    this.state = {
      data: undefined,
      url: ""
    }
  }

  getDataFromDb = () => {
    let url = this.props.location.pathname.substring(7);
    if (this.state.url !== url) {
      axios.post('http://'+BACKEND_IP+'/api/store/getStore', {
        storeId: url
      })
      .then((response) => {
        console.log(response.data.store);
        this.setState({
          data: response.data.store,
          url: url
        })
      })
      .catch((error) => {
        console.log(error);
      });
    }
  };

  displayProducts = () => {
    if (this.state.data !== undefined) {
      return (
        <MenuTabs categories={this.state.data.categories}/>
      )
    }
  }

  render() {
    this.getDataFromDb();
    if (this.state.data !== undefined) {
      return (
        <div style={{backgroundColor:"#2d2f2d",width:'100vw',paddingTop:40}}>
          <div style={{
            display: 'flex', flexDirection: 'column',
            alignItems: 'center',justifyContent: 'center',
            color: 'white', fontSize: '18px'}}>
            <img src={"http://"+BACKEND_IP+"/"+this.state.url+"/logo"}
            alt={"No Logo"}
            style={{marginTop:'30px',height:'80px'}}/>
            <div style={{paddingTop:'10px'}}>
              {this.state.data.name}
            </div>
            <div style={{paddingTop:'10px'}}>
              {this.state.data.address}
            </div>
            <div style={{paddingTop:'10px',marginBottom: '15px'}}>
              {this.state.data.phone}
            </div>
          </div>
          <div style={{width:'100%'}}>
            {this.displayProducts()}
          </div>
        </div>
      );
    } else {
      return (
        <div style={{height:'100vh',width:'100vw',backgroundColor:'#2d2f2d',
          display:'flex',justifyContent:'center',alignItems:'center'}}>
          <GreenSpin/>
        </div>
      )
    }
  }
}

export default Store;
