import React, { Component } from 'react'
import Main from './Main'
import DrawerMenu from './drawer/DrawerMenu'
import Footer from './Footer'
import Map from './map/Map'
import { Link, NavLink } from 'react-router-dom'
import Cookies from 'universal-cookie'
import TitleParticles from './components/animations/TitleParticles'
import baileysbudhouse from './assets/img/baileysbudhouse.png'
import { Button, ButtonGroup, Fade } from "@material-ui/core"
import VegPlantsPhoto from './assets/img/vegplants.jpg'
import LadybugPhoto from './assets/img/ladybug.jpg'
import VegCanopyPhoto from './assets/img/vegcanopy.jpg'
import VegPlants2Photo from './assets/img/vegplants2.jpg'
import VegPrettyPhoto from './assets/img/vegpretty.jpg'
import TwentyOnePlusDialog from './components/CustomDialog/TwentyOnePlusDialog'
import InstagramIcon from './assets/img/Instagram-Logo.png'
import Carousel from "react-slick"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from '@material-ui/core/typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AddIcon from '@material-ui/icons/Add'
import GridContainer from "./components/Grid/GridContainer"
import GridItem from "./components/Grid/GridItem"
import Card from "./components/Card/Card"

const useStyles = makeStyles(theme => ({
  item: {
    [theme.breakpoints.down('sm')]: {
      flexFlow:'column'
    },
    [theme.breakpoints.up('md')]: {
      flexFlow:'column'
    },
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  carousel: {
    [theme.breakpoints.down('sm')]: {
      height:'50vw',
      width:'90vw'
    },
    [theme.breakpoints.up('md')]: {
      height:'35vw',
      width:'60vw'
    },
    display:'flex',
    flexFlow:'column',
    justifyContent:'center',
    alignItems:'center'
  }
}));

export default function HomeMap(props) {
  const classes = useStyles();
  const [autoplay, setAutoplay] = React.useState(false);
  const [itemId, setItemId] = React.useState("");
  const theme = useTheme();
  let media = useMediaQuery(theme.breakpoints.up('sm'));

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    centerMode:true,
    variableWidth:true
  };

  return (
    <div style={{flex:1}}>
      <div style={{flex:1}}>

        <Typography align='center' style={{fontFamily:'Jokerman-custom',fontSize:'2vw',
          flex:1,color:'#00DFAF'}}>
        </Typography>
      </div>
        <img src={baileysbudhouse} alt='...' style={{height:'100vh'}}/>
      <Typography align='center' style={{fontFamily:'Jokerman-custom',fontSize:'2vw',
        flex:1,color:'#00DFAF'}}>
      </Typography>
    </div>
  );
}
