import React from "react";

export default function AddedSuccessAnim(props) {
  return (
    <lottie-player
        src="https://assets7.lottiefiles.com/datafiles/jXqHQIXI6oO6V47/data.json"
        background="transparent"  speed="2"
        style={{width: '200px', height: '200px'}} autoplay >
    </lottie-player>
  );
}
