import React from "react";

export default function FailAnim(props) {
  return (
    <lottie-player
        src="https://assets3.lottiefiles.com/packages/lf20_ed9D2z.json"
        background="transparent"  speed="1"
        style={{width: '200px', height: '200px'}} autoplay>
    </lottie-player>
  );
}
