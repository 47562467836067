import React from "react";

export default function GreenSpin(props) {
  return (
    <lottie-player
        src="https://assets7.lottiefiles.com/packages/lf20_4WIaLe.json"  background="transparent"  speed="1"
        style={{width: '200px', height: '200px'}}  loop autoplay >
    </lottie-player>
  );
}
