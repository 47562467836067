import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { authenticate } from '../actions'
import { store } from '../index'
import axios from 'axios'
import Cookies from 'universal-cookie'
import {BACKEND_IP} from '../constants/backendIp'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputAdornment from "@material-ui/core/InputAdornment"
import Icon from "@material-ui/core/Icon"
// @material-ui/icons
import Email from "@material-ui/icons/Email"
import People from "@material-ui/icons/People"
import Lock from '@material-ui/icons/Lock'
// core components
import Header from "../components/Header/Header.js"
import HeaderLinks from "../components/Header/HeaderLinks.js"
import Footer from "../components/Footer/Footer.js"
import GridContainer from "../components/Grid/GridContainer.js"
import GridItem from "../components/Grid/GridItem.js"
import Card from "../components/Card/Card.js"
import CardBody from "../components/Card/CardBody.js"
import CardHeader from "../components/Card/CardHeader.js"
import CardFooter from "../components/Card/CardFooter.js"
import CustomInput from "../components/CustomInput/CustomInput.js"
import CradleAnimation from "../components/Loading/Cradle"
import AddedSuccessAnim from '../components/animations/AddedSuccessAnim'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function AuthenticationDialog(props) {
  const classes = useStyles()
  const [openAuth, setOpenAuth] = React.useState(false)
  const [email, setEmail] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [errorMessage, setErrorMessage] = React.useState("")
  const [animation, setAnimation] = useState(<div></div>)
  const [animOpen, setAnimOpen] = useState(false)

  useEffect(() => {
    if (props.isOpen) {
      setOpenAuth(true)
    }
  }, [props.isOpen]);

  const handleClose = () => {
    setOpenAuth(false)
    // if (props.parent !== null) {
    //   props.parent.setState({dialog:false})
    // }
  };

  const handleAnimClose = () => {
    setAnimOpen(false)
  }

  const handleLoginSuccess = (data) => {
    setAnimation(<AddedSuccessAnim />)
    setTimeout(() => {
        setAnimOpen(false)
        const cookies = new Cookies();
        cookies.set('userToken', data.tokenExp, { path: '/' });
        cookies.set('userId', data.tokenId, { path: '/' });
        store.dispatch(props.authenticate(data.user));
        setOpenAuth(false);
    }, 700)
  }

  const authenticateUser = () => {
    setAnimOpen(true)
    setAnimation(<CradleAnimation />)
    axios.post('http://'+BACKEND_IP+'/api/auth/authenticate', {
      logemail: email,
      logpassword: password,
    })
    .then((response) => {
      console.log(response.data);
      if (response.data.success === true) {
        handleLoginSuccess(response.data);
      } else if (response.data.success === false) {
        setAnimOpen(false);
      }
    })
    .catch((error) => {
      console.log(error);
      setAnimOpen(false);
    });
  };

  return (
    <div>
    <Dialog
      open={openAuth}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
      {"Please Authenticate in Order to Proceed"}</DialogTitle>
      <DialogContent style={{paddingBottom:'10vh'}}>
        <CardBody>
          <CustomInput
            labelText="Email..."
            id="email"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "email",
              onChange: (e) => setEmail(e.target.value),
              endAdornment: (
                <InputAdornment position="end">
                  <Email className={classes.inputIconsColor} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            labelText="Password"
            id="pass"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: "password",
              onChange: (e) => setPassword(e.target.value),
              endAdornment: (
                <InputAdornment position="end">
                  <Lock className={classes.inputIconsColor} />
                </InputAdornment>
              ),
              autoComplete: "off"
            }}
          />
        </CardBody>
      </DialogContent>
      <DialogContentText style={{color:'red',alignSelf:'end',paddingRight:'20px'}}>
        {errorMessage}
      </DialogContentText>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={authenticateUser} color="primary">
          Authenticate
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={animOpen}
      keepMounted
      onClose={handleAnimClose}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}>
      {animation}
    </Dialog>
    </div>
  );
}

export default withRouter(connect(null, { authenticate })(AuthenticationDialog));
