import React, { Component } from 'react'
import Main from '../Main'
import DrawerMenu from '../drawer/DrawerMenu'
import Footer from '../Footer'
import { Link, NavLink } from 'react-router-dom'
import Cookies from 'universal-cookie'
import TitleParticles from '../components/animations/TitleParticles'
import baileysbudhouse from '../assets/img/baileysbudhouse.png'
import { Button, ButtonGroup, Fade } from "@material-ui/core"
import GP1 from '../assets/img/goldenpapaya/gp1.webp'
import GP2 from '../assets/img/goldenpapaya/gp2.webp'
import GP3 from '../assets/img/goldenpapaya/gp3.webp'
import GP4 from '../assets/img/goldenpapaya/gp4.webp'
import GP5 from '../assets/img/goldenpapaya/gp5.webp'
import GP6 from '../assets/img/goldenpapaya/gp6.webp'
import Logosubscribe from '../components/CustomDialog/LogoSubscribe'
import InstagramIcon from '../assets/img/Instagram-Logo.png'
import Carousel from "react-slick"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Typography from '@material-ui/core/typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import AddIcon from '@material-ui/icons/Add'
import GridContainer from "../components/Grid/GridContainer"
import GridItem from "../components/Grid/GridItem"
import Card from "../components/Card/Card"
import goldenpapaya from "../assets/img/goldenpapaya.webp"

const useStyles = makeStyles(theme => ({
  item: {
    [theme.breakpoints.down('sm')]: {
      flexFlow:'column'
    },
    [theme.breakpoints.up('md')]: {
      flexFlow:'column'
    },
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  carousel: {
    [theme.breakpoints.down('sm')]: {
      height:'65vw',
      width:'90vw'
    },
    [theme.breakpoints.up('md')]: {
      height:'50vw',
      width:'90vw'
    },
    display:'flex',
    flexFlow:'column',
    justifyContent:'center',
    alignItems:'center'
  }
}));

export default function Bdsj0243(props) {
  const classes = useStyles();
  const [autoplay, setAutoplay] = React.useState(false);
  const [itemId, setItemId] = React.useState("");
  const theme = useTheme();
  let media = useMediaQuery(theme.breakpoints.up('sm'));

  return (

      <div style={{display:'flex',flexDirection:'column'}}>
      <div>
        <img src={'https://green-thumb-growers.uk.r.appspot.com/labTesting/bdsj0243/terps.jpg'} alt='...'
          style={{height:'50vw'}}/>
      </div>
      <div>
        <img src={'https://green-thumb-growers.uk.r.appspot.com/labTesting/bdsj0243/tac.jpg'} alt='...'
          style={{height:'50vw'}}/>
      </div>
      </div>
  );
}
