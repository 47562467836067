import React from "react";
import HandWithJoint from "../../assets/img/Untitled.png"
export default function LikeFace(props) {
  return (
    <img
        src={HandWithJoint}
        style={{background:'transparent',position:'relative',right:'10px',bottom:'10px',width: '40px', height: '40px'}}  >
    </img>
  );
}
