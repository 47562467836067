import React, { Component } from 'react'
import Main from './Main'
import DrawerMenu from './drawer/DrawerMenu'
import Footer from './components/Footer/Footer'
import { Link, NavLink } from 'react-router-dom'
import Cookies from 'universal-cookie'
import TitleParticles from './components/animations/TitleParticles'
import baileysbudhouse from './assets/img/baileysbudhouse.png'
import { Button, ButtonGroup, Fade } from "@material-ui/core";
import TwentyOnePlusDialog from './components/CustomDialog/TwentyOnePlusDialog'
import Logosubscribe from './components/CustomDialog/LogoSubscribe'
import InstagramIcon from './assets/img/Instagram-Logo.png'
import ContactDialog from './components/CustomDialog/ContactDialog'
import './App.css'
import Typography from '@material-ui/core/typography'

export default function Header(props) {

  return(
    <div style={{backgroundColor: "#ffffff",width:'100%',height:'15vh'}}>
      <div style={{backgroundColor:"#ffffff",display: "flex",flexDirection: "column",height: "100vh"}}>
        <div style={{width:'98.5%',backgroundColor:"#ffffff",zIndex:5,
          fontFamily:'Raleway',color:'#2d2f2d',fontSize:'10px',position:"absolute"}}>
          <Fade in={props.verified} timeout={3000} >
            <ButtonGroup variant="contained" aria-label="outlined primary button group"
              style={{height:'15vh',width:'100%'}}>
              <NavLink to={'/thebuds'} href="#top" style={{color:'#2d2f2d'}}>
                <Button style={{width:'50vw',height:'15vh',backgroundColor:"#CACCE7",
                  fontSize:'5vw',fontFamily:'Jokerman-custom'}}>
                    The Map
                </Button>
              </NavLink>
              <NavLink to={'/thebaileys'} href="#top" style={{color:'#2d2f2d'}}>
                <Button style={{width:'50vw',height:'15vh',backgroundColor:"#CAE7D7",
                  fontSize:'4vw',fontFamily:'Jokerman-custom'}}>
                  Learn More
                </Button>
              </NavLink>
            </ButtonGroup>
          </Fade>
        </div>
      </div>
    </div>
  );
}

// return(
//   <div style={{backgroundColor: "#ffffff",width:'100%',height:'15vh'}}>
//     <div style={{backgroundColor:"#ffffff",display: "flex",flexDirection: "column",height: "100vh"}}>
//       <div style={{width:'98.5%',backgroundColor:"#ffffff",zIndex:5,
//         fontFamily:'Raleway',color:'#2d2f2d',fontSize:'10px',position:"absolute"}}>
//         <Fade in={props.verified} timeout={3000} >
//           <ButtonGroup variant="contained" aria-label="outlined primary button group"
//             style={{height:'15vh',width:'100%'}}>
//             <NavLink to={'/menu'} href="#top" style={{color:'#2d2f2d'}}>
//                 <Button style={{width:'33vw',height:'15vh',backgroundColor:"#CADAE7",
//                   fontSize:'5vw',fontFamily:'Jokerman-custom'}}>
//                   Delivery
//                 </Button>
//             </NavLink>
//             <NavLink to={'/thebuds'} href="#top" style={{color:'#2d2f2d'}}>
//               <Button style={{width:'33vw',height:'15vh',backgroundColor:"#CACCE7",
//                 fontSize:'5vw',fontFamily:'Jokerman-custom'}}>
//                   The Map
//               </Button>
//             </NavLink>
//             <NavLink to={'/thebaileys'} href="#top" style={{color:'#2d2f2d'}}>
//               <Button style={{width:'33vw',height:'15vh',backgroundColor:"#CAE7D7",
//                 fontSize:'4vw',fontFamily:'Jokerman-custom'}}>
//                 Learn More
//               </Button>
//             </NavLink>
//           </ButtonGroup>
//         </Fade>
//       </div>
//     </div>
//   </div>
// );
