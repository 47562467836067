import React, { Component, useEffect } from "react"
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { authenticate } from '../actions'
import { store } from '../index'
import axios from 'axios'
import {BACKEND_IP} from '../constants/backendIp'
import Cookies from 'universal-cookie'
import { makeStyles } from "@material-ui/core/styles"
import LocationOn from "@material-ui/icons/LocationOn"
import Button from '@material-ui/core/Button'
import Rating from '@material-ui/lab/Rating'
import AddIcon from '@material-ui/icons/AddTwoTone'
import StarRateIcon from '@material-ui/icons/StarRateTwoTone'
import FavoriteIcon from '@material-ui/icons/FavoriteTwoTone'
import GridContainer from "../components/Grid/GridContainer.js"
import GridItem from "../components/Grid/GridItem.js"
import Card from "../components/Card/Card.js"
import GreenSpin from "../components/Loading/GreenSpin"
import LikeHeart from "../components/animations/LikeHeart"
import LikeFace from "../components/animations/LikeFace"
import NoLikeFace from "../components/animations/NoLikeFace"
import AddToCartDialog from './AddToCartDialog'
import AuthenticationDialog from '../info/AuthenticationDialog'
import Carousel from "react-slick"
import styles from "../assets/jss/material-kit-react/views/componentsSections/carouselStyle.js"
import { container } from "../assets/jss/material-kit-react.js"

const useStyles = makeStyles(theme => ({
  item: {
    [theme.breakpoints.down('sm')]: {
      flexFlow:'column'
    },
    [theme.breakpoints.up('md')]: {
      flexFlow:'row'
    },
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    paddingBottom: '10px'
  },
  carousel: {
    [theme.breakpoints.down('sm')]: {
      width:'80vw'
    },
    [theme.breakpoints.up('md')]: {
      width:'60vw'
    },
    height:'auto',
    display:'flex',
    flexFlow:'column',
    justifyContent:'center',
    alignItems:'center'
  }
}));

function Item(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [item, setItem] = React.useState(false)
  const [favItem, setFavItem] = React.useState(null)
  const [favorited, setFavorited] = React.useState(null)
  const [favLoader, setFavLoader] = React.useState("none")
  const [openAuth, setOpenAuth] = React.useState(false)

  useEffect(() => {
    let id = props.location.pathname.substring(6);
    axios.post('http://'+BACKEND_IP+'/api/product/getItem', {
      id: id
    })
    .then((response) => {
      let responseItem = response.data.item;
      if (!item) {
        let calcRating = (Math.round((responseItem.rating/responseItem.numRatings)*2)/2).toFixed(1);
        setItem({
          itemId:responseItem.itemId,
          title:responseItem.title,
          price:responseItem.price,
          description:responseItem.description,
          rating:calcRating,
          numRatings:responseItem.numRatings,
          weights:responseItem.weights,
          sizes:responseItem.sizes
        })
      }
    })
    .catch((error) => {
      console.log(error)
    })
    if (favorited === null && props.auth !== -1) {
      setFavorited(false)
      props.auth.favorites.map((fav,index) => {
        if(fav.pic === props.location.pathname.substring(6)) {
          setFavorited(true)
          setFavItem(fav)
        }
      })
    }
  })

  const handleClickOpenAuth = () => {
    setOpenAuth(true)
    setTimeout(() => {
        setOpenAuth(false)
    }, 100)
  }

  const favoriteItem = () => {
    setFavLoader("loading")
    const cookies = new Cookies()
    let tokenExp = cookies.get('userToken')
    let tokenId = cookies.get('userId')
    if (tokenExp) {
      axios.post('http://'+BACKEND_IP+'/api/cart/addToFav', {
        tokenExp: tokenExp,
        tokenId: tokenId,
        itemId:item.itemId,
        title:item.title,
        pic:props.location.pathname.substring(6),
        price:item.price
      })
      .then((response) => {
        if (response.data.success === true) {
          setFavLoader("none")
          let authObj = props.auth
          let favoriteItem = {
            itemId:item.itemId,
            pic:props.location.pathname.substring(6),
            price:item.price,
            title:item.title
          }
          authObj.favorites.push(favoriteItem)
          store.dispatch(props.authenticate(authObj))
          setFavItem(favoriteItem)
        } else if (response.data.success === false) {
          setFavorited(false)
          setFavLoader("error")
          if (response.data.error.status === 400){
            setFavLoader("none")
            handleClickOpenAuth();
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setFavorited(false)
        setFavLoader("error")
      });
    } else {
      setFavorited(false)
      setFavLoader("none")
      handleClickOpenAuth();
    }
  }

  const unfavoriteItem = () => {
    const cookies = new Cookies();
    let tokenExp = cookies.get('userToken');
    let tokenId = cookies.get('userId');
    if (tokenExp) {
      axios.post('http://'+BACKEND_IP+'/api/cart/removeFromFav', {
        tokenExp: tokenExp,
        tokenId: tokenId,
        item: favItem
      })
      .then((response) => {
        if (response.data.success) {
          setFavLoader("none")
          let authObj = props.auth
          let newFavList = []
          authObj.favorites.map((fav,key) => {
            if (fav.itemId !== favItem.itemId) {
              newFavList.push(fav)
            }
          })
          authObj.favorites = newFavList
          store.dispatch(props.authenticate(authObj))
        } else if (response.data.success === false) {
          setFavorited(true)
          setFavLoader("error")
          if (response.data.error.status === 400){
            setFavLoader("none")
            handleClickOpenAuth();
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setFavorited(true)
        setFavLoader("error")
      });
    } else {
      setFavorited(true)
      setFavLoader("none")
      handleClickOpenAuth();
    }
  }

  const openAddToCart = () => {
    setOpen(true)
    setTimeout(() => {
        setOpen(false)
    }, 100);
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false
  };

  const showLoadMessage = () => {
    if (favLoader === "loading") {
      return (
        <div style={{height:15,color:'green'}}>
          Sending to your Favorites...
        </div>
      )
    } else if (favLoader === "error") {
      if (favorited) {
        return (
          <div style={{height:15,color:'red'}}>
            Error Removing From Favorites
          </div>
        )
      } else {
        return (
          <div style={{height:15,color:'red'}}>
            Error Sending to Favorites
          </div>
        )
      }
    } else {
      return (<div style={{height:15}}></div>)
    }
  }

  const showLikeFace = () => {
    if (favorited) {
      return (
        <div onClick={onFavoriteChange}
          style={{display:'flex',alignItems:'center',
          justifyContent:'center',color:'white'}}>
          <div>Favorited</div>
          <div style={{paddingLeft:40,paddingBottom:10}}>
            <LikeHeart />
          </div>
          <div><LikeFace /></div>
        </div>
      )
    } else {
      return (
        <div onClick={onFavoriteChange}
          style={{display:'flex',alignItems:'center',
          justifyContent:'center',color:'white'}}>
          <div>Add to Favorites</div>
          <div style={{paddingLeft:20}}><FavoriteIcon fontSize='large'/></div>
          <div style={{paddingLeft:10}}><NoLikeFace /></div>
        </div>
      )
    }
  }

  const onFavoriteChange = () => {
    if (favorited) {
      setFavorited(false)
      unfavoriteItem()
    } else {
      setFavorited(true)
      favoriteItem()
    }
  }

  if (item) {
    return (
      <div style={{backgroundColor: "#2d2f2d",paddingTop:'50px',minHeight:'100vh'}}>
        <div style={{display:'flex',justifyContent:'center'}}>
          <h1 style={{color: 'white',fontSize: '36px',
            fontFamily:'Raleway'}}>
            {item.title}
          </h1>
        </div>
        <div className={classes.item}>
          <GridContainer className={classes.carousel}>
            <GridItem xs={12} sm={12} md={8}>
              <Card carousel>
                <Carousel {...settings}>
                  <div>
                    <img src={"http://"+BACKEND_IP+"/"+props.location.pathname.substring(6)}
                      alt="First slide" className="slick-image"/>
                  </div>
                  <div>
                    <img src={"http://"+BACKEND_IP+"/"+props.location.pathname.substring(6)}
                      alt="Second slide" className="slick-image" />
                  </div>
                  <div>
                    <img src={"http://"+BACKEND_IP+"/"+props.location.pathname.substring(6)}
                      alt="Third slide" className="slick-image" />
                  </div>
                </Carousel>
              </Card>
            </GridItem>
            <Rating
              name="half-rating"
              defaultValue={item.rating}
              precision={0.5}
              disabled
              />
            <div style={{display:'flex',justifyContent:'center',
              color: 'white',fontSize: '11px',fontFamily:'Raleway'}}>
              {item.numRatings} ratings
            </div>
          </GridContainer>

          <div style={{ display: 'flex',flexDirection:'column',height:'300px',
            width:'50vw',marginLeft:'10px',marginRight:'10px',
            alignItems:'center',justifyContent:'center'}}>
            <h3 style={{color: 'white',fontSize: '22px',
              fontFamily:'Raleway'}}>
              {item.description}
            </h3>
            <h3 style={{color: 'white',fontSize: '22px',
              fontFamily:'Raleway'}}>
              {item.price}
            </h3>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={openAddToCart}
            >
              Add to Cart
            </Button>
          </div>
        </div>
        <div style={{display:'flex',flexFlow:'column',alignItems:'center'}}>
          <div>{showLoadMessage()}</div>
          <div>{showLikeFace()}</div>
        </div>
        <AddToCartDialog
          itemId={item.itemId}
          title={item.title}
          pic={props.location.pathname.substring(6)}
          price={item.price}
          weights={item.weights}
          sizes={item.sizes}
          isOpen={open}
        />
        <AuthenticationDialog
          itemId={item.itemId}
          title={item.title}
          pic={props.location.pathname.substring(6)}
          price={item.price}
          isOpen={openAuth}
          parent={null}
        />
      </div>
    );
  } else {
    return (
      <div style={{height:'100vh',width:'100vw',backgroundColor:'#2d2f2d',
        display:'flex',justifyContent:'center',alignItems:'center'}}>
        <GreenSpin/>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    auth: state.auth,
  }
)

export default withRouter(connect(mapStateToProps, { authenticate })(Item))
