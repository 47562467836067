import React, { useState, useEffect } from 'react'
import { Redirect, Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { animateCart } from '../actions';
import { store } from '../index';
import axios from 'axios'
import Cookies from 'universal-cookie'
import {BACKEND_IP} from '../constants/backendIp'
import AuthenticationDialog from '../info/AuthenticationDialog'
import QuantitySelector from '../components/CustomDropdown/QuantitySelector'
import AddAnimation from '../components/animations/AddAnimation'
import AddCartAnimation from '../components/animations/AddCartAnim'
import AddedSuccessAnim from '../components/animations/AddedSuccessAnim'
import FailAnim from '../components/animations/FailAnim'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function AddToCartDialog(props) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [animOpen, setAnimOpen] = React.useState(false)
  const [openAuth, setOpenAuth] = React.useState(false)
  const [size, setSize] = React.useState(-1)
  const [weight, setWeight] = React.useState("")
  const [quantity, setQuantity] = React.useState(1)
  const [errorMessage, setErrorMessage] = React.useState("")
  const [animation, setAnimation] = React.useState(<div></div>)

  useEffect(() => {
    if (props.isOpen) {
      setOpen(true)
    }
  }, [props.isOpen])

  const handleSizeChange = (num) => {
    setSize(num)
  }

  const handleQuantityChange = (num) => {
    setQuantity(num)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleAnimClose = () => {
    setAnimOpen(false)
  }

  const handleSuccessClose = () => {
    setOpen(false)
    setTimeout(() => {
        setAnimation(<AddedSuccessAnim />)
    }, 200)

    setTimeout(() => {
        setAnimOpen(false)
    }, 700)
    setTimeout(() => {
        store.dispatch(props.animateCart("anim"))
    }, 500)
    setTimeout(() => {
        store.dispatch(props.animateCart("full"))
    }, 1500)
  }

  const handleFailure = () => {
    setAnimation(<FailAnim />)
    setTimeout(() => {
        setAnimOpen(false)
    }, 700)
  }

  const handleClickOpenAuth = () => {
    setOpenAuth(true)
    setTimeout(() => {
        setOpenAuth(false)
    }, 100)
  }

  const addToCart = () => {
    setAnimOpen(true)
    setAnimation(<AddAnimation />)
    let index = props.sizes.indexOf(size)
    const cookies = new Cookies()
    let tokenExp = cookies.get('userToken')
    let tokenId = cookies.get('userId')
    if (tokenExp) {
      axios.post('http://'+BACKEND_IP+'/api/cart/addToCart', {
        tokenExp: tokenExp,
        tokenId: tokenId,
        itemId:props.itemId,
        title:props.title,
        pic:props.pic,
        price:props.price,
        size: size,
        weight: props.weights[index],
        quantity: quantity
      })
      .then((response) => {
        if (response.data.success === true) {
          handleSuccessClose()
        } else if (response.data.success === false) {
          handleFailure()
          if (response.data.error.status === 401){
            setErrorMessage("Item is already in your cart")
          } else if (response.data.error.status === 400){
            handleClickOpenAuth();
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setAnimOpen(false);
      });
    } else {
      setAnimOpen(false);
      handleClickOpenAuth();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
        {"Select your desired quantity of:"}</DialogTitle>
        <DialogContent style={{paddingBottom:'10vh'}}>
          <div style={{display:'flex'}}>
            <div>
              <img width='200vw' src={"http://"+BACKEND_IP+"/"+props.pic} alt={'/images/defaultLogo.png'}/>
              <DialogContentText id="alert-dialog-slide-description">
                {props.title}
              </DialogContentText>
            </div>
            <div style={{display:'flex',flexFlow:'column'}}>
              <QuantitySelector label='Size'
                setQuantity={handleSizeChange}
                weights={props.weights}
                sizes={props.sizes}
              />
              <QuantitySelector label='Quantity'
                setQuantity={handleQuantityChange}
                weights={[1,2,3,4]}
                sizes={[1,2,3,4]}
              />
            </div>
          </div>
        </DialogContent>
        <DialogContentText style={{color:'red',alignSelf:'end',paddingRight:'20px'}}>
          {errorMessage}
        </DialogContentText>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={addToCart} color="primary">
            Add to Cart
          </Button>
        </DialogActions>
      </Dialog>
      <AuthenticationDialog
        itemId={props.itemId}
        title={props.title}
        pic={props.pic}
        price={props.price}
        isOpen={openAuth}
        parent={null}
      />
      <Dialog open={animOpen}
      keepMounted
      onClose={handleAnimClose}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}>
        {animation}
      </Dialog>
    </div>
  );
}

export default withRouter(connect(null, {animateCart})(AddToCartDialog));
