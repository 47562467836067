import React from 'react'
import { NavLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import HomeIcon from '@material-ui/icons/HomeTwoTone'
import BagIcon from '@material-ui/icons/LocalMallTwoTone'
import SearchIcon from '@material-ui/icons/SearchTwoTone'
import MapIcon from '@material-ui/icons/MapTwoTone'



const useStyles = makeStyles(theme => ({
  products: {
    backgroundColor:'#1b5e20',
    minWidth: 240,
    width: 240
  },
  finder: {
    backgroundColor:'#4caf50',
    minWidth: 240,
    width: 240
  },
  stores: {
    backgroundColor:'#a5d6a7',
    minWidth: 240,
    width: 240
  },
  heading: {
    fontWeight:'bold',
    fontSize: 16,
    fontFamily:'Raleway',
    textDecoration: 'underline'
  },
  menuItem: {
    marginTop:20,
    fontSize:18,
    color:'black',
    textDecoration:'none'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontFamily:'Raleway'
  },
  link: {
    marginLeft: 5,
    fontSize:15,
    fontFamily: 'Raleway'
  },
}));

export default function StoresPanel() {
  const classes = useStyles();
  const [productsExpanded, setProductsExpanded] = React.useState(false);
  const [finderExpanded, setFinderExpanded] = React.useState(false);
  const [storesExpanded, setStoresExpanded] = React.useState(true);
  const menuItem = {display:'flex',flexFlow:'row',marginTop:'20px',
    fontSize:18,color:'black',textDecoration:'none'};
  const activeMenuItem = {display:'flex',flexFlow:'row',marginTop:'20px',
    fontSize:18,fontWeight:'bold',color:'black',textDecoration:'none'};

  const handleProductChange = () => {
    if (productsExpanded) {
      setProductsExpanded(false)
    } else {
      setProductsExpanded(true)
    }
  };

  const handleFinderChange = () => {
    if (finderExpanded) {
      setFinderExpanded(false)
    } else {
      setFinderExpanded(true)
    }
  };

  const handleStoresChange = () => {
    if (storesExpanded) {
      setStoresExpanded(false)
    } else {
      setStoresExpanded(true)
    }
  };

  return (
    <div>
      <ExpansionPanel className={classes.products}
        expanded={productsExpanded}
        onChange={handleProductChange}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Shop All Products</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display:'flex',flexFlow:'column'}}>
          <NavLink exact to='/' href="#top"
            activeStyle={activeMenuItem}
            style={menuItem}>
            <HomeIcon />
            <div className={classes.link}>
              Home
            </div>
          </NavLink>
          <NavLink to='/categories' href="#top"
            activeStyle={activeMenuItem} style={menuItem}>
            <BagIcon />
            <div className={classes.link}>
              Categories
            </div>
          </NavLink>
          <NavLink to='/search' href="#top"
            activeStyle={activeMenuItem} style={menuItem}>
            <SearchIcon />
            <div className={classes.link}>
              Search
            </div>
          </NavLink>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel className={classes.finder}
        expanded={finderExpanded}
        onChange={handleFinderChange}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Store Finder</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display:'flex',flexFlow:'column'}}>
          <NavLink exact to='/storefinder' href="#top"
            activeStyle={activeMenuItem}
            style={menuItem}>
            <MapIcon />
            <div className={classes.link}>
              Map
            </div>
          </NavLink>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <ExpansionPanel className={classes.stores}
        expanded={storesExpanded}
        onChange={handleStoresChange}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}>Dispensaries</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{display:'flex',flexFlow:'column'}}>
          <NavLink to='/store/gt' href="#top"
            activeStyle={activeMenuItem}
            style={menuItem}>
            <div className={classes.link}>
              Green Thumb Growers
            </div>
          </NavLink>
          <NavLink to='/store/pp' href="#top"
            activeStyle={activeMenuItem} style={menuItem}>
            <div className={classes.link}>
              Pot Palace
            </div>
          </NavLink>
          <NavLink to='/store/nj' href="#top"
            activeStyle={activeMenuItem} style={menuItem}>
            <div className={classes.link}>
              Najarian Music
            </div>
          </NavLink>
          <NavLink to='/store/bs' href="#top"
            activeStyle={activeMenuItem} style={menuItem}>
            <div className={classes.link}>
              Blowin Smoke
            </div>
          </NavLink>
          <NavLink to='/store/rs' href="#top"
            activeStyle={activeMenuItem} style={menuItem}>
            <div className={classes.link}>
              Rumplespliffskin's
            </div>
          </NavLink>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
