import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import Cookies from 'universal-cookie'
import {BACKEND_IP} from '../constants/backendIp'
import Counter from '../containers/counter'
import Construction from '../components/animations/Construction'

class Checkout extends Component {
  state = {
    authenticated: null,
    data: null,
    logemail: null,
    logpassword: null,
    email: null,
    username: null,
    password: null,
    passwordConf: null
  };

  render() {
    if (this.props.auth !== -1) {
      return(
        <div style={{ backgroundColor: "#2d2f2d",paddingTop:80}}>
            <div style={{
              display: 'flex', flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white', fontSize: '22px' }}>
              <div style={{fontSize: '46px' }}>Checkout</div>
              <div style={{ paddingTop:10, paddingBottom:100}}>
              <Construction />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div style={{ backgroundColor: "#2d2f2d",paddingTop:'40px'}}>
            <div style={{
              paddingTop:200, paddingBottom:200,
              display: 'flex', flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white', fontSize: '22px' }}>
              <div style={{fontSize: '36px' }}>Checkout</div>
              <div style={{ marginTop: '100px'}}>Please log in so you can add
                items to your cart, and proceed to checkout.</div>
            </div>
          </div>
          <img src={'/images/herb.jpg'}
          alt={'/images/herb.jpg'}
          style={{width:'100%'}}>
          </img>
        </div>
      );
    }
  }
}

const mapStateToProps = state => (
  {
    auth: state.auth,
  }
);

export default withRouter(connect(mapStateToProps, null)(Checkout));
