import { useEffect } from 'react';

const DutchieScript = (url) => {
  console.log("step 1");
  console.log(url);
  console.log(document.body);
  useEffect(() => {
  console.log(document.body);
  const divis = document.createElement('div');
  divis.id = "menu";
  const script = document.createElement('script');
  script.src = url;
  script.async = "";
  script.id = "dutchie--embed__script";
  // console.log("step 3");
  document.body.appendChild(divis);
  divis.appendChild(script);
  // console.log("step 4");

    return () => {
      // console.log("step 5");
      document.body.removeChild(script);
      // console.log("step 6");
    }
  }, [url])
  console.log("step 0000");
  console.log(document.body);
};

export default DutchieScript;
