import React, { Component } from 'react'
import Main from './Main'
import DrawerMenu from './drawer/DrawerMenu'
import Header from './Header'
import Footer from './Footer'
import { Link, NavLink } from 'react-router-dom'
import Cookies from 'universal-cookie'
import TitleParticles from './components/animations/TitleParticles'
import baileysbudhouse from './assets/img/baileysbudhouse.png'
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Button, ButtonGroup, Fade } from "@material-ui/core";
import BaileysDesc from './assets/img/baileysSocialEquityDesc.webp'
import BaileysPhoto from './assets/img/10584.jpg'
import GusInFlowering from './assets/img/gusinflowerrooms.jpg'
import PhilipTaki from './assets/img/philiptaki.jpg'
import AlexGusFlowering from './assets/img/AlexGusFlowering.jpg'
import GusShocked from './assets/img/gusshocked.jpg'
import PhilGusBroom from './assets/img/philgussweeping.jpg'
import PhilBroom from './assets/img/philwithbroomcentered.jpg'
import GusPeatMoss from './assets/img/gusonpeatmoss.jpg'
import Logosubscribe from './components/CustomDialog/LogoSubscribe'
import InstagramIcon from './assets/img/Instagram-Logo.png'
import Carousel from "react-slick"
import TowerView from './assets/img/towerview.webp'
import GrafitiView from './assets/img/grafitiview.webp'
import DamView from './assets/img/damview.webp'
import DamBuildingView from './assets/img/damandbuilding.webp'
import MillPhoto from './assets/img/rainbowmill.jpg'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/typography'
import AddIcon from '@material-ui/icons/Add'
import GridContainer from "./components/Grid/GridContainer"
import GridItem from "./components/Grid/GridItem"
import Card from "./components/Card/Card"

const useStyles = makeStyles(theme => ({
  item: {
    [theme.breakpoints.down('sm')]: {
      flexFlow:'column'
    },
    [theme.breakpoints.up('md')]: {
      flexFlow:'row'
    },
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  carousel: {
    [theme.breakpoints.down('sm')]: {
      height:'50vh',
      width:'50vh'
    },
    [theme.breakpoints.up('md')]: {
      height:'50vh',
      width:'50vh'
    },
    display:'flex',
    flexFlow:'column',
    justifyContent:'center',
    alignItems:'center'
  }
}));

export default function Baileys(props) {
  const classes = useStyles();
  const [autoplay, setAutoplay] = React.useState(false);
  const [itemId, setItemId] = React.useState("");
  const theme = useTheme();
  let media = useMediaQuery(theme.breakpoints.up('sm'));

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  const baileysCarousel = () => {
    return (
      <div className={classes.item}>
        <GridContainer className={classes.carousel}>
          <GridItem >
            <Card carousel>
              <Carousel {...settings}>
                <div>
                  <img src={BaileysPhoto} alt='...'
                    style={{height:'50vh',width:'50vh'}}/>
                </div>
                <div>
                  <img src={GusInFlowering} alt='...'
                    style={{height:'50vh',width:'50vh'}}/>
                </div>
                <div>
                <img src={PhilipTaki} alt='...'
                  style={{height:'50vh',width:'50vh'}}/>
                </div>
                <div>
                <img src={AlexGusFlowering} alt='...'
                  style={{height:'50vh',width:'50vh'}}/>
                </div>
                <div>
                  <img src={GusShocked} alt='...'
                    style={{height:'50vh',width:'50vh'}}/>
                </div>
                <div>
                  <img src={PhilGusBroom} alt='...'
                    style={{height:'50vh',width:'50vh'}}/>
                </div>
                <div>
                <img src={PhilBroom} alt='...'
                  style={{height:'50vh',width:'50vh'}}/>
                </div>
                <div>
                  <img src={GusPeatMoss} alt='...'
                    style={{height:'50vh',width:'50vh'}}/>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }

  const millCarousel = () => {
    return (
      <div className={classes.item}>
        <GridContainer className={classes.carousel}>
          <GridItem >
            <Card carousel>
              <Carousel {...settings}>
                <div>
                  <img src={MillPhoto} alt='...'
                    style={{height:'50vh',width:'50vh'}}/>
                </div>
                <div>
                  <img src={TowerView} alt='...'
                    style={{height:'50vh',width:'50vh'}}/>
                </div>
                <div>
                <img src={DamView} alt='...'
                  style={{height:'50vh',width:'50vh'}}/>
                </div>
                <div>
                  <img src={DamBuildingView} alt='...'
                    style={{height:'50vh',width:'50vh'}}/>
                </div>
                <div>
                  <img src={GrafitiView} alt='...'
                    style={{height:'50vh',width:'50vh'}}/>
                </div>
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }

  return (
    <div>
      <Header verified={props.verified} style={{height:'15vh'}}/>
      <Footer verified={props.verified}/>
      <div style={{display:'flex',justifyContent:'center'}}>
      <img src={BaileysDesc} alt='...' style={{width:'80vw'}}/>
      </div>
      <Typography align='center' style={{fontSize:'2vw',fontFamily:'Alfa Slab One',
      paddingTop:'20px',paddingBottom:'20px'}}>
        If you or your immediate family suffered or are suffering persecution
        from the war on drugs and you are looking to enter the legal cannabis
        industry in MA, we highly recommend that you look into the <a href="https://masscannabiscontrol.com/equity-programs/" target="_blank">
           Cannabis Control Commission's Social Equity Program
        </a>
      </Typography>
      <Typography align='center' style={{fontSize:'2vw',fontFamily:'Alfa Slab One'}}>
        If you are already a Social Equity Program Participant with the CCC
        and you are interested in getting a tour of the Bailey's Buds facility
        and learning more about Cannabis Licensing and Cultivation in MA, then
        please send an email to info@baileysbuds.com. We are here as a resource
        to you, to help craft cannabis and social equity flourish!
      </Typography>
      {baileysCarousel()}
      <Typography align='center' style={{width:'100%',fontSize:'2vw',fontFamily:'Alfa Slab One'}}>
        We bought a dilapidated mill & took it down to the studs to create a state of the art weed factory.
      </Typography>
      <Typography align='center' style={{width:'100%',fontSize:'2vw',fontFamily:'Alfa Slab One'}}>
        Built in 1900, it replaced a previous mill on the site that burned down.  With top notch sprinkler systems installed, that won’t happen again, but the mill will soon be facilitating a different kind of burning.
      </Typography>
      {millCarousel()}
      <Typography align='center' style={{width:'100%',fontSize:'2vw',fontFamily:'Alfa Slab One'}}>
        Overlooking scenic Beaver Brook in Dracut, Massachusetts there’s no better place to grow!
      </Typography>
    </div>
  );
}

// class Baileys extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       verified: true
//     };
//   }
//
//   // when component mounts, first thing it does is fetch all existing data in our db
//   // then we incorporate a polling logic so that we can easily see if our db has
//   // changed and implement those changes into our UI
//   componentDidMount() {
//     this.renderRedirect();
//   }
//
//   renderRedirect = () => {
//     const cookies = new Cookies();
//     if (cookies.get('verified') !== 'true') {
//       this.setState(
//         {verified: false}
//       )
//     }
//   }
//
//   setCookieTrue() {
//     const cookies = new Cookies();
//     cookies.set('verified', 'true', { path: '/' })
//     this.setState(
//       {verified: true}
//     )
//     console.log("here")
//   }
//
//   render(){
//     return (
//       <div style={{backgroundColor:"#ffffff",height:'70vh',width:'100vw',
//         display:'flex',flexDirection:'column',
//         alignItems:'center',justifyContent:'center'}}>
//         <Fade in={true} timeout={1000}>
//           <img src={BaileysPhoto} alt='...'
//             style={{height:'60vh',marginTop:'10vh'}}/>
//         </Fade>
//       </div>
//     );
//   }
// }
//
// export default Baileys;
