import React, { useState, useEffect } from "react";
import axios from 'axios';
import Cookies from 'universal-cookie';
import {BACKEND_IP} from '../constants/backendIp';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { authenticate } from '../actions';
import { store } from '../index';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Dialog from "@material-ui/core/Dialog";
// @material-ui/icons
import Email from "@material-ui/icons/Email"
import Lock from '@material-ui/icons/Lock'
// core components
import GridContainer from "../components/Grid/GridContainer.js"
import GridItem from "../components/Grid/GridItem.js"
import Button from "../components/CustomButtons/Button.js"
import Card from "../components/Card/Card.js"
import CardBody from "../components/Card/CardBody.js"
import CardHeader from "../components/Card/CardHeader.js"
import CardFooter from "../components/Card/CardFooter.js"
import CustomInput from "../components/CustomInput/CustomInput.js"
import CradleAnimation from "../components/Loading/Cradle"
import AddedSuccessAnim from '../components/animations/AddedSuccessAnim'
import FailAnim from '../components/animations/FailAnim'
import styles from "../assets/jss/material-kit-react/views/loginPage.js"

const useStyles = makeStyles(styles);

function LoginPage(props) {
  const [cardAnimation, setCardAnimation] = useState("cardHidden")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [animation, setAnimation] = useState(<div></div>)
  const [animOpen, setAnimOpen] = useState(false)
  const [loginFailure, setLoginFailure] = useState("")
  const classes = useStyles()
  const { ...rest } = props

  useEffect(() => {
    if (cardAnimation === "cardHidden") {
      setCardAnimation("")
    }
  })

  const handleAnimClose = () => {
    setAnimOpen(false)
  }

  const handleLoginSuccess = (data) => {
    setAnimation(<AddedSuccessAnim />)
    setTimeout(() => {
        setAnimOpen(false)
        const cookies = new Cookies();
        cookies.set('userToken', data.tokenExp, { path: '/' })
        cookies.set('userId', data.tokenId, { path: '/' })
        store.dispatch(props.authenticate(data.user))
    }, 700)
  }

  const handleLoginFailure = () => {
    setAnimation(<FailAnim />)
    setLoginFailure("Login Failed, Try Again")
    setTimeout(() => {
        setAnimOpen(false)
    }, 700)
  }

  const authenticateUser = () => {
    setAnimOpen(true)
    setAnimation(<CradleAnimation />)
    axios.post('http://'+BACKEND_IP+'/api/auth/authenticate', {
      logemail: email.toLowerCase(),
      logpassword: password,
    })
    .then((response) => {
      if (response.data.success === true) {
        handleLoginSuccess(response.data);
      } else if (response.data.success === false) {
        handleLoginFailure();
      }
    })
    .catch((error) => {
      console.log(error);
      handleLoginFailure();
    });
  };

  return (
    <div style={{minHeight:'100vh',backgroundColor: "#2d2f2d"}}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={4}>
            <Card className={classes[cardAnimation]}>
              <form onSubmit={authenticateUser} className={classes.form}>
                <CardHeader color="primary" className={classes.cardHeader}>
                  <h4>Login</h4>
                  <div style={{height:'30px'}}>
                  </div>
                </CardHeader>
                <p className={classes.divider}></p>
                <CardBody>
                  <CustomInput
                    labelText="Email..."
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "email",
                      onChange: (e) => setEmail(e.target.value),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputIconsColor} />
                        </InputAdornment>
                      )
                    }}
                  />
                  <CustomInput
                    labelText="Password"
                    id="pass"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      type: "password",
                      onChange: (e) => setPassword(e.target.value),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Lock className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                      autoComplete: "off"
                    }}
                  />
                  <div style={{color:'red',display:'flex',
                    justifyContent:'center'}}>
                    {loginFailure}
                  </div>
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button simple color="primary" size="lg"
                    onClick={authenticateUser}>
                    Get started
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
      <Dialog open={animOpen}
        keepMounted
        onClose={handleAnimClose}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}>
        {animation}
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => (
  {
    auth: state.auth,
    menu: state.menu,
  }
);

export default withRouter(connect(mapStateToProps, { authenticate })(LoginPage));
