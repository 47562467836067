import React, { Component } from 'react'
import Main from './Main'
import DrawerMenu from './drawer/DrawerMenu'
import Footer from './components/Footer/Footer'
import { Link, NavLink } from 'react-router-dom'
import Cookies from 'universal-cookie'
import TitleParticles from './components/animations/TitleParticles'
import baileysbudhouse from './assets/img/baileysbudhouse.png'
import { Button, ButtonGroup, Fade } from "@material-ui/core";
import TwentyOnePlusDialog from './components/CustomDialog/TwentyOnePlusDialog'
import Logosubscribe from './components/CustomDialog/LogoSubscribe'
import InstagramIcon from './assets/img/Instagram-Logo.png'
import ContactDialog from './components/CustomDialog/ContactDialog'
import './App.css'
import Typography from '@material-ui/core/typography'
// import Header from '../components/Header/Header'
// import FFF_Tusj from './assets/fonts/FFF_Tusj-webfont.woff'

class App extends Component {
  constructor(props) {
    super(props);
    this.setCookieTrue = this.setCookieTrue.bind(this);
    this.state = {
      verified: true
    };
  }

  // when component mounts, first thing it does is fetch all existing data in our db
  // then we incorporate a polling logic so that we can easily see if our db has
  // changed and implement those changes into our UI
  componentDidMount() {
    this.renderRedirect();
  }

  renderRedirect = () => {
    const cookies = new Cookies();
    if (cookies.get('verified') !== 'true') {
      this.setState(
        {verified: false}
      )
    }
  }

  setCookieTrue() {
    const cookies = new Cookies();
    cookies.set('verified', 'true', { path: '/' })
    this.setState(
      {verified: true}
    )
  }
  render(){
    return (
      <div style={{backgroundColor:'white'}}>
            <Fade in={!this.state.verified} timeout={100}>
              <TwentyOnePlusDialog setCookieTrue={this.setCookieTrue}/>
            </Fade>
            <div>
              <Main verified={this.state.verified}/>
            </div>
      </div>
    );
  }
}

export default App;

//   render(){
//     return (
//       <div style={{position:'relative',flex:1,minHeight:'100vh'}}>
//         <div style={{backgroundColor: "#ffffff",
//           width:'100%',height:'15vh'}}>
//           <div style={{backgroundColor:"#ffffff",display: "flex",flexDirection: "column",height: "100vh"}}>
//             <div style={{width:'98.5%',backgroundColor:"#ffffff",zIndex:5,
//               fontFamily:'Raleway',color:'#2d2f2d',fontSize:'10px',position:"absolute"}}>
//               <Fade in={this.state.verified} timeout={10000} >
//                 <ButtonGroup variant="contained" aria-label="outlined primary button group"
//                   style={{height:'15vh',width:'100%'}}>
//                   <NavLink to={'/thebaileys'} href="#top" style={{color:'#2d2f2d'}}>
//                       <Button style={{width:'33vw',height:'15vh',backgroundColor:"#CADAE7",
//                         fontSize:'2vw',fontFamily:'Jokerman-custom'}}>
//                         The Baileys
//                       </Button>
//                   </NavLink>
//                   <NavLink to={'/thebuds'} href="#top" style={{color:'#2d2f2d'}}>
//                     <Button style={{width:'33vw',height:'15vh',backgroundColor:"#CACCE7",
//                       fontSize:'2vw',fontFamily:'Jokerman-custom'}}>
//                         The Buds
//                     </Button>
//                   </NavLink>
//                   <NavLink to={'/themill'} href="#top" style={{color:'#2d2f2d'}}>
//                     <Button style={{width:'33vw',height:'15vh',backgroundColor:"#CAE7D7",
//                       fontSize:'2vw',fontFamily:'Jokerman-custom'}}>
//                       The Mill
//                     </Button>
//                   </NavLink>
//                 </ButtonGroup>
//               </Fade>
//             </div>
//             <Fade in={!this.state.verified} timeout={100}>
//               <TwentyOnePlusDialog setCookieTrue={this.setCookieTrue}/>
//             </Fade>
//             <div style={{height:"auto",width:'100%',backgroundColor:"#ffffff",position:"absolute"}}>
//               <Main />
//             </div>
//           </div>
//           <div style={{
//             width:'100%',height:'inherit',backgroundColor:"#ffffff",zIndex:5,position:"absolute",bottom:0,overflow:'auto',display:'block',
//             marginTop:"auto",fontFamily:'Raleway',color:'#2d2f2d',fontSize:'10px'}}>
//
//             <Fade in={this.state.verified} timeout={20000} style={{width:'100%'}}>
//               <nav style={{
//                 display: 'flex', flexDirection: 'row',
//                 alignItems:'center',justifyContent:'center',alignSelf:'center'}}>
//                 <Button>
//                   <ContactDialog/>
//                 </Button>
//                 <Button style={{paddingLeft:'3vw',paddingRight:'3vw'}}>
//                   <a href="https://www.instagram.com/baileysbuds_/" target="_blank" rel="noopener noreferrer">
//                     <img src={InstagramIcon} alt='...'
//                       style={{height:'8vh'}}/>
//                   </a>
//                 </Button>
//                 <Button>
//                   <Logosubscribe/>
//                 </Button>
//               </nav>
//             </Fade>
//             <div>Marijuana Microbusiness</div>
//             <div>CCC License MB281692</div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }
//
// export default App;

//   render(){
//     return (
//       <div style={{backgroundColor: "#ffffff",
//         width:'100%',height:'15vh'}}>
//         <div style={{backgroundColor:"#ffffff",display: "flex",flexDirection: "column",height: "100vh"}}>
//           <div style={{width:'98.5%',backgroundColor:"#ffffff",zIndex:5,
//             fontFamily:'Raleway',color:'#2d2f2d',fontSize:'10px',position:"absolute"}}>
//             <Fade in={this.state.verified} timeout={10000} >
//               <ButtonGroup variant="contained" aria-label="outlined primary button group"
//                 style={{height:'15vh',width:'100%'}}>
//                 <NavLink to={'/thebaileys'} href="#top" style={{color:'#2d2f2d'}}>
//                     <Button style={{width:'33vw',height:'15vh',backgroundColor:"#CADAE7",
//                       fontSize:'2vw',fontFamily:'Jokerman-custom'}}>
//                       The Baileys
//                     </Button>
//                 </NavLink>
//                 <NavLink to={'/thebuds'} href="#top" style={{color:'#2d2f2d'}}>
//                   <Button style={{width:'33vw',height:'15vh',backgroundColor:"#CACCE7",
//                     fontSize:'2vw',fontFamily:'Jokerman-custom'}}>
//                       The Buds
//                   </Button>
//                 </NavLink>
//                 <NavLink to={'/themill'} href="#top" style={{color:'#2d2f2d'}}>
//                   <Button style={{width:'33vw',height:'15vh',backgroundColor:"#CAE7D7",
//                     fontSize:'2vw',fontFamily:'Jokerman-custom'}}>
//                     The Mill
//                   </Button>
//                 </NavLink>
//               </ButtonGroup>
//             </Fade>
//           </div>
//           <Fade in={!this.state.verified} timeout={100}>
//             <TwentyOnePlusDialog setCookieTrue={this.setCookieTrue}/>
//           </Fade>
//           <div style={{height:"auto",width:'100%',backgroundColor:"#ffffff",position:"absolute"}}>
//             <Main />
//           </div>
//         </div>
//         <div style={{
//           width:'100%',backgroundColor:"#ffffff",zIndex:5,position:"absolute",
//           marginTop:"auto",fontFamily:'Raleway',color:'#2d2f2d',fontSize:'10px'}}>
//
//           <Fade in={this.state.verified} timeout={20000} style={{width:'100%'}}>
//             <nav style={{
//               display: 'flex', flexDirection: 'row',
//               alignItems:'center',justifyContent:'center',alignSelf:'center'}}>
//               <Button>
//                 <ContactDialog/>
//               </Button>
//               <Button style={{paddingLeft:'3vw',paddingRight:'3vw'}}>
//                 <a href="https://www.instagram.com/baileysbuds_/" target="_blank" rel="noopener noreferrer">
//                   <img src={InstagramIcon} alt='...'
//                     style={{height:'8vh'}}/>
//                 </a>
//               </Button>
//               <Button>
//                 <Logosubscribe/>
//               </Button>
//             </nav>
//           </Fade>
//           <div>Marijuana Microbusiness</div>
//           <div>CCC License MB281692</div>
//         </div>
//       </div>
//     );
//   }
// }
//
// export default App;


// <div style={{flex:1}}>
//   <Typography style={{display:'flex',fontFamily:'Jokerman-custom',fontSize:'5vw',color:'#00DFAF'}}>
//     Are you 21 or older?
//   </Typography>
//   <nav style={{
//     display: 'flex', flexDirection: 'row',width:'100vw',
//     alignItems:'center',justifyContent:'space-around',alignSelf:'center'}}>
//     <Button variant="contained" aria-label="outlined primary button group"
//     onClick={() => this.setCookieTrue()}
//     style={{zIndex:5,backgroundColor:"#CAE7D7",textDecoration: 'none'}}>
//       <div id="yes" style={{color:'#2d2f2d'}}>I'm 21+</div>
//     </Button>
//     <Button variant="contained" aria-label="outlined primary button group"
//       onClick={() => {
//         alert('Entry is not permitted');
//       }}
//       style={{zIndex:5,backgroundColor:"#CADAE7",textDecoration: 'none'}}>
//       <div id="no" style={{color:'#2d2f2d'}}>I'm not 21 yet</div>
//     </Button>
//   </nav>
// </div>
