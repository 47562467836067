import React from 'react'
import Construction from '../components/animations/Construction'

export default function Registration(props) {


  return (
    <Construction />
  )
}
