import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { SwipeableDrawer, Fab, Checkbox, Tab, Tabs } from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Flexview from 'react-flexview'
import Draggable from 'react-draggable'
import StoreIcon from '@material-ui/icons/StoreTwoTone'
import InfoIcon from '@material-ui/icons/InfoTwoTone'
import CartIcon from '@material-ui/icons/ShoppingCartTwoTone'
import PersonIcon from '@material-ui/icons/PersonTwoTone'
import DrawerHome from './DrawerHome'
import SearchButton from './SearchButton'
import AddCartAnimation from '../components/animations/AddCartAnim'
import AddCartAnimationFast from '../components/animations/AddCartAnimFast'

const useStyles = makeStyles(theme => ({
  floatingButton: {
    [theme.breakpoints.down('xs')]: {
      maxWidth:'150px'
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth:'300px'
    },
  },
  container: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent:'flex-end'
  },
}));

const styles = {
  tab: {
    minWidth: 80
  },
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

class DrawerMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dragPosition: null,
      store: true,
      info: false,
      cart: false,
      searching: false,
      value: 0
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize',this.updateWindowDimensions);
  }

  toggleDrawer = (open) => () => {
    this.setState({isOpen: open});
  };

  toggleSearch = () => {
    if (this.state.searching) {
      this.setState({searching: false});
    } else {
      this.setState({searching: true});
    }
  }

  handleChange = (event, newValue) => {
    this.setState({value: newValue});
  };

  storeClicked = () => {
    this.setState({store:true,info:false,cart:false})
  }

  infoClicked = () => {
    this.setState({store:false,info:true,cart:false})
  }

  cartClicked = () => {
    this.setState({store:false,info:false,cart:true})
  }

  updateWindowDimensions = () => {
    this.setState({dragPosition: {x: -5, y: 0}});
    this.setState({dragPosition: null});
  }

  displayCart = () => {
    console.log(this.props.animCart)
    if (this.props.animCart === "empty") {
      return (<CartIcon fontSize='large'/>)
    } else if (this.props.animCart === "anim") {
      return(<AddCartAnimation />)
    } else if (this.props.animCart === "full") {
      return(<AddCartAnimationFast />)
    }
  }

  render() {
    const draggableSearchStyle = this.props.search ? {position:'fixed',zIndex:5,paddingRight:'180px'} : {display:'none'};
    const draggableMenuStyle = this.props.menu ? {position:'fixed',zIndex:5} : {display:'none'};
    const draggableProfileStyle = this.props.profile ? {position:'fixed',zIndex:5,paddingRight:'120px'} : {display:'none'};
    const draggableCartStyle = this.props.cart ? {position:'fixed',zIndex:5,paddingRight:'60px'} : {display:'none'};
    const { classes } = this.props;

    return (
      <div>
        <SwipeableDrawer
          anchor="left"
          open={this.state.isOpen}
          onOpen={this.toggleDrawer(true)}
          onClose={this.toggleDrawer(false)}
          transitionDuration={{enter:500,exit:500}}
        >
          <Tabs
            variant="fullWidth"
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab classes={{root:classes.tab}} label='Shop'
              icon={<StoreIcon />} onClick={this.storeClicked}
              key={0} {...a11yProps(0)}/>
            <Tab classes={{root:classes.tab}} label='Info'
              icon={<InfoIcon />} onClick={this.infoClicked}
              key={1} {...a11yProps(1)}/>
            <Tab classes={{root:classes.tab}} label='Cart'
              icon={<CartIcon />} onClick={this.cartClicked}
              key={2} {...a11yProps(2)}/>
          </Tabs>
          <Flexview column hAlignContent="center"
            style={{height:'100vh',backgroundColor:'white'}}>
            <div>
              <DrawerHome
                store={this.state.store}
                info={this.state.info}
                cart={this.state.cart}/>
            </div>
          </Flexview>
        </SwipeableDrawer>
        <div style={{display:'flex',justifyContent:'flex-end'}}>
          <Flexview
            hAlignContent="right">
            <Flexview
              style={draggableSearchStyle}>
              <SearchButton position={this.state.dragPosition}
                onStart={this.handleStart}
                onDrag={this.handleDrag}
                onStop={this.handleStop}/>
            </Flexview>
            <Flexview
              style={draggableProfileStyle}>
              <Draggable
                axis="both"
                handle=".handle"
                position={this.state.dragPosition}
                grid={[1, 1]}
                scale={1}
                onStart={this.handleStart}
                onDrag={this.handleDrag}
                onStop={this.handleStop}>
                <NavLink to='/info/account' href="#top">
                  <Fab className="handle"
                    style={{zIndex:5,textDecoration:'none',
                    margin: '1vw'}}>
                    <PersonIcon fontSize='large'/>
                  </Fab>
                </NavLink>
              </Draggable>
            </Flexview>
            <Flexview
              style={draggableCartStyle}>
              <Draggable
                axis="both"
                handle=".handle"
                position={this.state.dragPosition}
                grid={[1, 1]}
                scale={1}
                onStart={this.handleStart}
                onDrag={this.handleDrag}
                onStop={this.handleStop}>
                <NavLink to='/cart/cart' href="#top">
                  <Fab className="handle"
                    style={{zIndex:5,textDecoration:'none',
                    margin: '1vw'}}>
                    {this.displayCart()}
                  </Fab>
                </NavLink>
              </Draggable>
            </Flexview>
            <Flexview
              style={draggableMenuStyle}>
              <Draggable
                axis="both"
                handle=".handle"
                position={this.state.dragPosition}
                grid={[1, 1]}
                scale={1}
                onStart={this.handleStart}
                onDrag={this.handleDrag}
                onStop={this.handleStop}>
                <Fab className="handle"
                  onClick={this.toggleDrawer(true)}
                  style={{zIndex:5,textDecoration:'none',
                  margin: '1vw'}}>
                  <img
                    style={{height:25,width:25,zIndex:5,position:'relative',textDecoration:'none'}}
                    src={'/images/hamburger.png'}
                    alt={'/images/greenthumb.png'} />
                </Fab>
              </Draggable>
            </Flexview>
          </Flexview>
        </div>
      </div>
    );
  }
}

DrawerMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => (
  {
    search: state.search,
    cart: state.cart,
    animCart: state.animCart,
    profile: state.profile,
    menu: state.menu,
  }
);

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(DrawerMenu)))
