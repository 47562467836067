import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux'
import App from './App'
import './index.css'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorker from './serviceWorker'
import { configureStore } from "@reduxjs/toolkit"
import rootReducer from './reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import "./assets/scss/material-kit-react.scss?v=1.8.0"
import Cursor from "react-custom-pointer"
import LikeFace from "./components/animations/LikeFace"
import Joint from "./assets/img/cartoonHandJoint.png"
import WebFont from 'webfontloader'
import './fonts/Jokerman/Jokerman.ttf'

const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer, {}, compose(
  applyMiddleware(),
  window.devToolsExtension ? window.devToolsExtension() : f => f,
));

export const persistor = persistStore(store);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "##82809F"
    },
    secondary: {
      main: "#000000"
    }
  },
});

function Index(props) {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Droid Sans', 'Chilanka', 'Rock Salt', 'Megrim', 'Cabin Sketch',
          'Bungee Shade','Ranchers','Alfa Slab One']
      }
    });
   }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
            <App/>
          </MuiThemeProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

ReactDOM.render(
  <Index />,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// function Index(props) {
//   return (
//     <Provider store={store}>
//       <PersistGate persistor={persistor}>
//         <BrowserRouter>
//           <MuiThemeProvider theme={theme}>
//             <App/>
//             <Cursor type="default">
//               <LikeFace/>
//             </Cursor>
//           </MuiThemeProvider>
//         </BrowserRouter>
//       </PersistGate>
//     </Provider>
//   )
// }
