import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { logout } from '../actions';
import { store } from '../index';
import axios from 'axios';
import Cookies from 'universal-cookie'
import {BACKEND_IP} from '../constants/backendIp';
import LoginPage from './LoginPage';
import GreenSpin from "../components/Loading/GreenSpin";
import Button from '../components/CustomButtons/Button';
import { Typography } from '@material-ui/core'
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';

class Account extends Component {

  getLogout = () => {
    if (this.props.auth !== -1) {
      const cookies = new Cookies();
      let tokenExp = cookies.get('userToken');
      let tokenId = cookies.get('userId');
      if (tokenExp) {
        axios.post('http://'+BACKEND_IP+'/api/auth/logout', {
          tokenExp: tokenExp,
          tokenId: tokenId
        })
        .then((response) => {
          cookies.remove('userToken', { path: '/' });
          cookies.remove('userId', { path: '/' });
          store.dispatch(this.props.logout());
        })
        .catch((error) => {
          console.log(error);
        });
      } else {
        store.dispatch(this.props.logout());
      }
    }
  };

  render() {
    if (this.props.auth !== -1) {
      return(
        <div>
          <div style={{paddingBottom:200, backgroundColor: "#2d2f2d",
            display: 'flex', flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color:'white',fontSize:'24px'}}>
            <div style={{display:'flex',flexDirection:'row',
              paddingTop:50,paddingBottom:40,margin:'4vw'}}>
              <Typography style={{fontSize: '28px',color: 'white',
                fontFamily:'Raleway',marginRight:'5px'}}>
                Logout
              </Typography>
              <Button color="secondary" size="lg"
                onClick={() => this.getLogout()}
                style={{textDecoration:'none',marginLeft:'5px'}}>
                <ExitToAppTwoToneIcon fontSize='large'/>
              </Button>
            </div>
            <div style={{fontSize: '36px' }}>Account: {this.props.auth.username}</div>
            <div style={{display:'flex',flexFlow:'column',alignItems:'center',
              fontSize:'18px',color:'white'}}>
              <div style={{fontSize: '28px',marginTop:'60px',
                marginBottom:'20px',textDecoration:'underline'}}>
                Address
              </div>
              <div>{this.props.auth.address.street}</div>
              <div style={{marginTop:5}}>{this.props.auth.address.town}</div>
              <div style={{marginTop:5,marginBottom:40}}>{this.props.auth.address.zip}</div>
              <Link to='/info/settings' href="#top">
                <Button color="primary" size="lg"
                  style={{textDecoration:'none',marginLeft:'5px'}}>
                  Go to my Settings
                </Button>
              </Link>
            </div>
          </div>
          <img src={'/images/herb.jpg'}
          alt={'/images/herb.jpg'}
          style={{width:'100%'}}>
          </img>
        </div>
      );
    } else {
      return (
        <LoginPage />
      );
    }
  }
}

const mapStateToProps = state => (
  {
    auth: state.auth,
  }
);

export default withRouter(connect(mapStateToProps, { logout })(Account));
