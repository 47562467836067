import React from "react";

export default function SheepCart(props) {
  return (
    <lottie-player
        src="https://assets9.lottiefiles.com/packages/lf20_IPnqnf.json" 
        background="transparent"  speed="1"
        style={{width: '200px', height: '200px'}}  loop autoplay >
    </lottie-player>
  );
}
