import React from "react";

export default function AddCartAnim(props) {
  return (
    <lottie-player
        src="https://assets8.lottiefiles.com/packages/lf20_apdaRT.json"
        background="transparent"  speed="1"
        style={{width: '30px', height: '30px'}} autoplay >
    </lottie-player>
  );
}
