import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Flexview from 'react-flexview'
import StoresPanel from './panels/StoresPanel'
import InfoPanel from './panels/InfoPanel'
import CartPanel from './panels/CartPanel'

export default function DrawerHome(props) {
  const menuItem = {marginTop:'20px',fontSize:18,color:'black',
    textDecoration:'none'};
  const activeMenuItem = {marginTop:'20px',fontSize:18,
    fontWeight:'bold',color:'green',textDecoration:'none'};

  if(props.store===true){
    return(
      <div>
        <Flexview column
          style={{height:'50vh'}}>
          <StoresPanel />
        </Flexview>
      </div>
    );
  } else if(props.info===true) {
    return(
      <InfoPanel />
    );
  } else if(props.cart===true){
    return(
      <CartPanel />
    );
  }
}
