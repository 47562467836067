import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { Switch, Route } from 'react-router-dom'
import TheBaileys from './Baileys.js'
import TheBuds from './Buds.js'
import TheMill from './Mill.js'
import Learn from './Learn.js'
import HomeMap from './HomeMap.js'
import Menu from './Menu.js'
// import Home from './map/MapHome'
import Categories from './homeViews/Categories'
import Search from './homeViews/Search'
import StoreFinder from './map/StoreFinder'
import Store from './stores/Store'
import Item from './stores/Item'
import Account from './info/Account'
import Settings from './info/Settings'
import Help from './info/help/Help'
import Cart from './cart/Cart'
import Favorites from './cart/Favorites'
import Notify from './cart/Notify'
import Checkout from './cart/Checkout'
import Payment from './cart/Payment'
import History from './cart/History'
import Registration from './cart/Registration'
import GoldenPapaya from './strains/goldenpapaya'
import LibertyHaze from './strains/libertyhaze'
import NoxiousBrew from './strains/noxiousbrew'
import Mojo from './strains/mojo'
import Trenchtown from './strains/trenchtown'
import Bdsj0243 from './labtesting/bdsj0243'

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }
  render() {
    return this.props.children;
  }
}

const Scroll = withRouter(ScrollToTop);

const Main = (props) => (
  <main>
    <Scroll>
      <Switch>
        <Route exact path='/'>
          <TheBuds verified={props.verified} />
        </Route>
        <Route exact path='/thebaileys'>
          <TheBaileys verified={props.verified} />
        </Route>
        <Route exact path='/thebuds'>
          <TheBuds verified={props.verified}/>
        </Route>
        <Route exact path='/themill'>
          <TheMill verified={props.verified} />
        </Route>
        <Route exact path='/learn' component={Learn}/>
        <Route path='/search' component={Search}/>
        <Route exact path='/storefinder' component={StoreFinder}/>
        <Route path='/store/' component={Store}/>
        <Route path='/item' component={Item}/>
	      <Route exact path='/info/account' component={Account}/>
	      <Route exact path='/info/settings' component={Settings}/>
        <Route exact path='/info/help' component={Help}/>
        <Route exact path='/cart/cart' component={Cart}/>
        <Route exact path='/cart/favorites' component={Favorites}/>
        <Route exact path='/cart/notify' component={Notify}/>
     	  <Route exact path='/cart/checkout' component={Checkout}/>
        <Route exact path='/cart/payment' component={Payment}/>
	      <Route exact path='/cart/history' component={History}/>
        <Route exact path='/cart/registration' component={Registration}/>
        <Route exact path='/nugcity/granada/goldenpapaya' component={GoldenPapaya}/>
        <Route exact path='/nugcity/philly/libertyhaze' component={LibertyHaze}/>
        <Route exact path='/nugcity/losangeles/noxiousbrew' component={NoxiousBrew}/>
        <Route exact path='/nugcity/london/mojo' component={Mojo}/>
        <Route exact path='/nugcity/trenchtown' component={Trenchtown}/>
        <Route exact path='/labtesting/bdsj0243' component={Bdsj0243}/>
        <Route exact path='/menu' >
          <Menu verified={props.verified} />
        </Route>
      </Switch>
    </Scroll>
  </main>
)

export default Main
