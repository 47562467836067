import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import Typography from '@material-ui/core/Typography'
import Cookies from 'universal-cookie'

export default function TwentyOnePlusDialog(props) {
  const [open, setOpen] = React.useState(true);
  const [email, setEmail] = React.useState("")

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleYes = () => {
    props.setCookieTrue();
    setOpen(false);
  };

  const handleNo = () => {
  };

  return (
    <div  style={{
      display: 'flex', flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'}}>
    

      <Dialog open={open} onClose={handleNo} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{fontSize:20,color:'black'}}>
            Are you 21 or older?
          </DialogContentText>
          <DialogContentText style={{fontSize:20,color:'black'}}>

          </DialogContentText>
          <DialogContentText style={{fontSize:20,color:'black'}}>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo} color="primary">
            <Typography style={{fontSize:14}}>I'm under 21</Typography>
          </Button>
          <Button onClick={handleYes}
            color="primary"
          >
            <Typography style={{fontSize:14}}>I'm 21+</Typography>
          </Button>
        </DialogActions>
        </Dialog>

    </div>
  );
}
