import { ActionTypes } from '../actions';

const AnimateCartReducer = (state = "empty", action) => {
  switch (action.type) {
    case ActionTypes.ANIMATECART:
      return action.payload;
    default:
      return state;
  }
};

export default AnimateCartReducer;
