import React from "react";

export default function Construction(props) {
  return (
    <div style={{height:'100vh',width:'100vw',
      backgroundColor:'#2d2f2d',color:'white',
      display:'flex',justifyContent:'center',
      alignItems:'center',flexFlow:'column'}}>
      <lottie-player
          src="https://assets2.lottiefiles.com/packages/lf20_AQcLsD.json"
          background="transparent"  speed="1"
          style={{width: '200px', height: '200px'}}  loop autoplay >
      </lottie-player>
      <h1>This Page Is Under Construction</h1>
    </div>
  );
}
