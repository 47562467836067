import React from "react";

export default function LikeFace(props) {
  return (
    <lottie-player
        src="https://assets6.lottiefiles.com/datafiles/UurjqgEl6ZhhENKYxrtHenjU5Wl3Rp7LBbWB4uVL/AE_JSON/17-EmojiReaction.json"
        background="transparent"  speed="1"
        style={{width: '100px', height: '100px'}} >
    </lottie-player>
  );
}
