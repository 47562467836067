import React, { Component } from 'react'
import { Link, NavLink } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { Button, ButtonGroup, Fade } from "@material-ui/core"
import ContactDialog from '../CustomDialog/ContactDialog'
import Logosubscribe from '../../components/CustomDialog/LogoSubscribe'
import InstagramIcon from '../../assets/img/Instagram-Logo.png'

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      verified: true
    };
  }

  // when component mounts, first thing it does is fetch all existing data in our db
  // then we incorporate a polling logic so that we can easily see if our db has
  // changed and implement those changes into our UI
  componentDidMount() {
    this.renderRedirect();
  }

  renderRedirect = () => {
    const cookies = new Cookies();
    if (cookies.get('verified') !== 'true') {
      this.setState(
        {verified: false}
      )
    }
  }

  setCookieTrue() {
    const cookies = new Cookies();
    cookies.set('verified', 'true', { path: '/' })
    this.setState(
      {verified: true}
    )
    console.log("here")
  }

  render(){
    return (
      <div style={{
        zIndex:1,display:'flex',flexDirection:'column',
        alignItems:'center',justifyContent:'flex-end',
        height:'20vh',width:'100vw',backgroundColor:"#ffffff",
        fontFamily:'Raleway',color:'#2d2f2d',fontSize:'18px'}}>
        <Fade in={!this.state.verified} timeout={5000}>
          <div>
            <div style={{display:'flex',justifyContent:'center'}}>Only 21+ permitted</div>
            <nav style={{
              display: 'flex', flexDirection: 'row'}}>
              <Button variant="contained" aria-label="outlined primary button group"
              onClick={() => this.setCookieTrue()}
              style={{zIndex:1,margin:'5vw',backgroundColor:"#CAE7D7",textDecoration: 'none'}}>
                <div id="yes" style={{color:'#2d2f2d'}}>I'm 21+</div>
              </Button>
              <Button variant="contained" aria-label="outlined primary button group"
                onClick={() => {
                  alert('Entry is not permitted');
                }}
                style={{zIndex:1,margin:'5vw',backgroundColor:"#CADAE7",textDecoration: 'none'}}>
                <div id="no" style={{color:'#2d2f2d'}}>I'm not 21 yet</div>
              </Button>
            </nav>
          </div>
        </Fade>
        <Fade in={this.state.verified} timeout={20000}>
          <nav style={{
            display: 'flex', flexDirection: 'row',marginTop:'-10vh',marginBottom:'10vh'}}>
            <Button>
              <ContactDialog/>
            </Button>
            <Button style={{paddingLeft:'3vw',paddingRight:'3vw'}}>
              <a href="https://www.instagram.com/baileysbuds__/" target="_blank" rel="noopener noreferrer"
                style={{zIndex:1}}>
                <img src={InstagramIcon} alt='...'
                  style={{height:'8vh'}}/>
              </a>
            </Button>
            <Button>
              <Logosubscribe/>
            </Button>
          </nav>
        </Fade>
      </div>
    );
  }
}

export default Footer;
