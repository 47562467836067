import { ActionTypes } from '../actions';

const AuthReducer = (state = -1, action) => {
  switch (action.type) {
    case ActionTypes.AUTHENTICATE:
      return action.payload;
    case ActionTypes.LOGOUT:
      return -1;
    default:
      return state;
  }
};

export default AuthReducer;
