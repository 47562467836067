import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import Cookies from 'universal-cookie'
import {BACKEND_IP} from '../constants/backendIp'
import Button from "../components/CustomButtons/Button"

class History extends Component {
  state = {
    authenticated: null,
    data: null,
    logemail: null,
    logpassword: null,
    email: null,
    username: null,
    password: null,
    passwordConf: null
  };

  render() {
    if (this.props.auth !== -1) {
      return(
        <div>
          <div style={{ backgroundColor: "#2d2f2d",paddingTop:'40px'}}>
              <div style={{
                display: 'flex', flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white', fontSize: '18px' }}>
                <div style={{fontSize: '46px' }}>Order History</div>
                <div style={{ paddingTop:200, paddingBottom:300,
                  display: 'flex', flexDirection: 'column',
                  alignItems: 'center',justifyContent: 'center'}}>
                <div>No orders yet.</div>
                <Link to={'/'} href="#top" style={{margin:20}}>
                  <Button color='primary'>Browse Products</Button>
                </Link>
                <div>Go ahead, get yourself something nice</div>
              </div>
            </div>
          </div>
          <img src={'/images/foliage.jpg'}
          alt={'/images/foliage.jpg'}
          style={{width:'100%'}}>
          </img>
        </div>
      );
    } else {
      return (
        <div>
          <div style={{ backgroundColor: "#2d2f2d",paddingTop:'40px'}}>
            <div style={{
              paddingTop:200, paddingBottom:200,
              display: 'flex', flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white', fontSize: '18px' }}>
              <div style={{fontSize: '36px' }}>Order History</div>
              <div style={{ marginTop: '100px'}}>Please log in to view your
                order history.
              </div>
            </div>
          </div>
          <img src={'/images/herb.jpg'}
          alt={'/images/herb.jpg'}
          style={{width:'100%'}}>
          </img>
        </div>
      );
    }
  }
}

const mapStateToProps = state => (
  {
    auth: state.auth,
  }
);

export default withRouter(connect(mapStateToProps, null)(History));
