import React from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie'
import {BACKEND_IP} from '../constants/backendIp';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { searchbutton, cartbutton,
    profilebutton, menubutton } from '../actions';
import { store } from '../index';
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  browserSetting: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 11
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 22
    },
    color: 'white',
    fontFamily:'Raleway',
    paddingTop:20
  },
}));

function Settings(props) {
  const classes = useStyles()

  const toggleSearchChecked = () => {
    if(props.search){
      store.dispatch(props.searchbutton(false));
    }else{
      store.dispatch(props.searchbutton(true));
    }
  };

  const toggleCartChecked = () => {
    if(props.cart){
      store.dispatch(props.cartbutton(false));
    }else{
      store.dispatch(props.cartbutton(true));
    }
  };

  const toggleProfileChecked = () => {
    if(props.profile){
      store.dispatch(props.profilebutton(false));
    }else{
      store.dispatch(props.profilebutton(true));
    }
  };

  const toggleMenuChecked = () => {
    if(props.menu){
      store.dispatch(props.menubutton(false));
    }else{
      store.dispatch(props.menubutton(true));
    }
  };

  const displayBrowserSettings = () => {
    return (
      <div style={{display:'flex',flexDirection:'column',
        alignItems:'center',backgroundColor: "#2d2f2d"}}>
        <Typography style={{fontSize: '28px',color: 'white',
          textDecoration:'underline',fontFamily: 'Raleway'}}>
          Customize You Experience:
        </Typography>
        <Typography style={{fontSize: '18px',color: 'white',
          fontFamily: 'Raleway'}}>
          (Only affects your current browser)
        </Typography>
        <div style={{display:'flex',flexDirection:'row',width:'100vw'}}>
          <div style={{
            display: 'flex', flexDirection: 'column',
            alignItems: 'flex-end',marginRight:'10px',
            width:'50%',paddingBottom:200 }}>
            <div className={classes.browserSetting}>
              Show Search Button:
            </div>
            <div className={classes.browserSetting}>
              Show Cart Button:
            </div>
            <div className={classes.browserSetting}>
              Show Profile/Login Button:
            </div>
            <div className={classes.browserSetting}>
              Show Menu Button:
            </div>
          </div>
          <div style={{
            display: 'flex', flexDirection: 'column',
            alignItems: 'flex-start',marginLeft:'10px',
            color: 'white', fontSize: '24px',fontFamily: 'Raleway',
            width:'50%',paddingBottom:200}}>
            <div onClick={toggleSearchChecked}
              style={{paddingTop:11}}>
              <Checkbox
                checked={props.search}
                style={{color:'green'}}/>
            </div>
            <div onClick={toggleCartChecked}
              style={{paddingTop:4}}>
              <Checkbox
                checked={props.cart}
                style={{color:'green'}}/>
            </div>
            <div onClick={toggleProfileChecked}
              style={{paddingTop:1}}>
              <Checkbox
                checked={props.profile}
                style={{color:'green'}}/>
            </div>
            <div onClick={toggleMenuChecked}
              style={{paddingTop:1}}>
              <Checkbox
                checked={props.menu}
                style={{color:'green'}}/>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (props.auth !== -1) {
    return(
      <div style={{display:'flex',flexDirection:'column',minHeight:'100vh',
        alignItems:'center',paddingTop:50,backgroundColor: "#2d2f2d"}}>
        <Typography style={{fontSize: '46px',color: 'white',
          textDecoration:'underline',fontFamily: 'Raleway'}}>
          Settings
        </Typography>
        <div style={{
          display: 'flex', flexDirection: 'column',alignItems: 'center',
          color: 'white', fontSize: '24px',fontFamily: 'Raleway',
          width:'50%',paddingTop:20,paddingBottom:20}}>
          <div style={{paddingTop:20}}>User: {props.auth.username}</div>
        </div>
        <div>{displayBrowserSettings()}</div>
        <img src={'/images/foliage.jpg'}
        alt={'/images/foliage.jpg'}
        style={{width:'100%'}}>
        </img>
      </div>
    );
  } else {
    return (
      <div>
        <div style={{minHeight:'100vh',backgroundColor: "#2d2f2d"}}>
          <div style={{
            paddingTop:100, paddingBottom:20,
            display: 'flex', flexDirection: 'column',
            alignItems: 'center',justifyContent: 'center',
            color: 'white', fontSize: '18px' }}>
            <div style={{fontSize: '36px' }}>Sorry, you're not logged in.</div>
            <div style={{ marginTop: '100px'}}>In order to set up an account, you must register with your desired dispensary.</div>
          </div>
        </div>
        <div>{displayBrowserSettings()}</div>
        <img src={'/images/herb.jpg'}
        alt={'/images/herb.jpg'}
        style={{width:'100%'}}>
        </img>
      </div>
    );
  }
}

const mapStateToProps = state => (
  {
    auth: state.auth,
    search: state.search,
    cart: state.cart,
    profile: state.profile,
    menu: state.menu,
  }
);

export default withRouter(connect(mapStateToProps,
    { searchbutton, cartbutton, profilebutton, menubutton })(Settings));
